import React, { useEffect, useId, useMemo, useRef, useState } from "react";
import L, {
  LatLngExpression,
  Map,
  Popup as PopupType,
  icon,
  point
} from "leaflet";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  useMapEvents
} from "react-leaflet";
import {
  ICluster,
  IDevice,
  ILocationDevice,
  IMapView,
  IOption,
  IPopupConfig
} from "@interfaces/index";
import LocationCluster from "./dash-map-cluster-component";
import {
  useAuthStore,
  useDashboardStore,
  useFleetAndDevicesStore
} from "@/store";
import { FunnelIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { Badge, Button } from "@tremor/react";
import {
  decodeBase64toUTF8,
  reactSelectClassNames
} from "@/app/shared/utils/helper.util";
import RouterIconBlue from "@assets/icons/router-blue.svg";
import RouterIconRed from "@assets/icons/router-red.svg";
import ReactSelect from "react-select";
import { Tooltip } from "react-tooltip";
import useGeoMapStore from "@/store/dashboard/geo-map.store";

const defaultIconConfig = {
  iconRetinaUrl: require("leaflet/dist/images/marker-icon.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
};

L.Icon.Default.mergeOptions(defaultIconConfig);

const INIT_ICON_SIZE = 34;

const MapView: React.FC<IMapView> = ({
  showExample,
  definition,
  devices,
  clusters,
  fleetsData,
  tagsData,
  bounding_box,
  geoMapDevicesWithShadows,
  selectedFleetsForMapFilter,
  selectedTagsForMapFilter,
  setSelectedFleetsForMapFilter,
  setSelectedTagsForMapFilter,
  onChangeBoundingBox
}) => {
  const mapRef = useRef<Map>();
  const resizeObserverRef = React.useRef<ResizeObserver>(null);

  const [iconSize, setIconSize] = useState(INIT_ICON_SIZE);

  const flyToDeviceId = useGeoMapStore(
    (state) => state.geoMapSelectedDeviceId
  );

  const [filterMenuVisible, setFilterMenuVisible] = useState(false);

  const createPanelAppearance = useDashboardStore(
    (state) => state.createPanelAppearance
  );

  const center = (bounding_box: number[]) => {
    return {
      lng: (bounding_box[0] + bounding_box[2]) / 2,
      lat: (bounding_box[1] + bounding_box[3]) / 2
    };
  };

  const mapCenter = center(bounding_box) as LatLngExpression;

  const Bounds = () => {
    const maphand = useMap();
    useMapEvents({
      zoomend: () => {
        onChangeBoundingBox(
          maphand
            .getBounds()
            .toBBoxString()
            .split(",")
            .map((ele) => +ele)
        );
      },
      moveend: () => {
        onChangeBoundingBox(
          maphand
            .getBounds()
            .toBBoxString()
            .split(",")
            .map((ele) => +ele)
        );
      }
    });
    return null;
  };

  const id = useId();

  useEffect(() => {
    const resizeObserver = resizeObserverRef.current;
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (!mapRef.current) {
      return () => {};
    }

    mapRef.current.on("zoomend", () => {
      const zoom = mapRef.current.getZoom();

      const newSize = zoom * (1 / 6) * INIT_ICON_SIZE;

      setIconSize(newSize);
    });
  }, []);

  return (
    <div className="h-full w-full p-2 mb-2 -mt-[15px]">
      <MapContainer
        ref={(ref) => {
          if (!ref) return;
          mapRef.current = ref;

          const container = document.getElementById(id);
          if (!container) return;

          L.DomEvent.disableClickPropagation(
            container
          ).disableScrollPropagation(container);

          resizeObserverRef.current = new ResizeObserver(() => {
            if (!ref) {
              return;
            }
            if (ref.getContainer()) {
              ref?.invalidateSize({ pan: false });
            }
          });
          if (container) {
            resizeObserverRef.current.observe(container);
          }
        }}
        id={id}
        center={mapCenter}
        zoom={16}
        zoomSnap={0.45}
        style={{
          width: "100%",
          height: showExample ? "300px" : "calc(100% - 0px)",
          marginTop: "6px",
          borderRadius: "6px",
          zIndex: 0
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {devices.map((device: ILocationDevice) =>
          showExample ? (
            <Marker
              icon={
                createPanelAppearance?.popupConfigs?.[
                  createPanelAppearance?.configuringShadowDefId
                ]?.iconURL
                  ? icon({
                      iconUrl:
                        createPanelAppearance?.popupConfigs?.[
                          createPanelAppearance?.configuringShadowDefId
                        ]?.iconURL,
                      iconSize: point(34, 34)
                    })
                  : icon({
                      ...defaultIconConfig,
                      iconSize: point(24, 40),
                      iconAnchor: point(0, 0)
                    })
              }
              position={{ lat: device.latitude, lng: device.longitude }}
            >
              <CustomPopup
                isOpen={!!createPanelAppearance.configuringShadowDefId}
                device={device}
                config={
                  createPanelAppearance?.popupConfigs?.[
                    createPanelAppearance?.configuringShadowDefId
                  ]
                }
                showExample
              />
            </Marker>
          ) : (
            <RouterIconWithPopup
              key={device.device_id}
              device={device}
              iconSize={iconSize}
              devicesWithShadows={geoMapDevicesWithShadows}
              config={definition?.popupConfigs?.[device.shadow_definition_id]}
            />
          )
        )}
        {clusters.map((cluster: ICluster) => (
          <LocationCluster key={cluster.cluster_id} {...cluster} />
        ))}
        <Bounds />
        <ZoomToArea
          device={devices?.find((d) => d.device_id === flyToDeviceId) ?? null}
        />
      </MapContainer>
      <div className="absolute flex flex-col items-end top-[3rem] right-4 gap-2">
        <Button
          icon={FunnelIcon}
          className="p-3"
          onClick={() => setFilterMenuVisible(!filterMenuVisible)}
        />
        {filterMenuVisible ? (
          <div className="p-4 rounded-md shadow-md bg-background">
            <div className="flex text-sm gap-2 mb-2">
              <span>Add Filters</span>
            </div>
            <h4 className="mt-2">Fleets</h4>
            <ReactSelect
              placeholder="Filter by Fleets"
              isSearchable
              isMulti
              options={fleetsData.fleets.map((fleet) => ({
                label: fleet.fleet_name,
                value: fleet.id
              }))}
              isLoading={fleetsData.isLoading}
              value={selectedFleetsForMapFilter}
              onChange={(opt: IOption[]) => {
                setSelectedFleetsForMapFilter(opt ?? []);
              }}
              classNames={reactSelectClassNames}
              className="w-[250px]"
            />
            <h4 className="mt-2">Tags</h4>
            <ReactSelect
              placeholder="Filter by Tags"
              isSearchable
              isMulti
              options={tagsData.tags.map((tag) => ({
                label: tag.name,
                value: tag.id
              }))}
              isLoading={tagsData.isLoading}
              value={selectedTagsForMapFilter}
              onChange={(opt: IOption[]) => {
                setSelectedTagsForMapFilter(opt ?? []);
              }}
              classNames={reactSelectClassNames}
              className="w-[250px]"
            />
          </div>
        ) : null}
      </div>

      <Tooltip
        id="filters-info-tooltip"
        variant="light"
        opacity={1}
        className="shadow-lg"
      >
        Filters may take up to 5s to apply.
      </Tooltip>
    </div>
  );
};

const RouterIconWithPopup: React.FC<{
  device: ILocationDevice;
  config: IPopupConfig;
  devicesWithShadows?: IDevice[];
  iconSize?: number;
}> = ({ device, config, devicesWithShadows, iconSize }) => {
  const [geoMapDevices] = useGeoMapStore((state) => [state.geoMapDevices]);
  const deviceWithShadow = useMemo(
    () => geoMapDevices?.find((d) => d.id === device.device_id),
    [geoMapDevices, device.device_id]
  );

  const shadow = useMemo(() => {
    try {
      return JSON.parse(
        decodeBase64toUTF8(deviceWithShadow?.current_shadow ?? "") || "{}"
      );
    } catch (err) {
      console.log(err);
      return {};
    }
  }, [deviceWithShadow?.current_shadow]);

  const internetActive = useMemo(() => {
    if (!deviceWithShadow?.connected) {
      return false;
    }
    if (shadow.ifaces?.length) {
      const wanIfaces = shadow.ifaces?.filter((iface) =>
        [
          shadow?.wan1Mapping,
          shadow?.wan2Mapping,
          shadow?.wan3Mapping
        ].includes(iface.iface)
      );

      return wanIfaces?.some((iface) => iface.internetActive);
    }
  }, [shadow, deviceWithShadow]);

  return (
    <Marker
      icon={icon({
        iconUrl: deviceWithShadow?.connected ? RouterIconBlue : RouterIconRed,
        iconSize: point(iconSize, iconSize)
      })}
      position={{ lat: device.latitude, lng: device.longitude }}
    >
      <CustomPopup
        isOpen={false}
        device={device}
        config={config}
        internetActive={internetActive}
        devicesWithShadows={geoMapDevices}
      />
    </Marker>
  );
};

interface ICustomPopupProps {
  isOpen: boolean;
  device: ILocationDevice;
  config?: IPopupConfig;
  devicesWithShadows?: IDevice[];
  internetActive?: boolean;
  showExample?: boolean;
}

const CustomPopup: React.FC<ICustomPopupProps> = ({
  isOpen,
  device,
  config,
  devicesWithShadows,
  internetActive,
  showExample
}) => {
  const map = useMap();
  const popupRef = useRef<PopupType>(null);

  const org = useAuthStore((state) => state.user?.selectedOrg);
  const proj = useFleetAndDevicesStore((state) => state.selectedProject);
  // const flyToDeviceId = useGeoMapStore(
  //   (state) => state.geoMapSelectedDeviceId
  // );

  // FIXME: Open popup
  // useEffect(() => {
  //   if (flyToDeviceId && popupRef.current && map) {
  //     if (
  //       device.device_id === flyToDeviceId &&
  //       device?.latitude &&
  //       device?.longitude
  //     ) {
  //       // popupRef.current.openOn(map);
  //       setTimeout(() => {
  //         popupRef.current.openOn(map);
  //       });
  //     }
  //   }

  //   return () => {
  //     if (map) {
  //       map.closePopup();
  //     }

  //     if (popupRef.current) {
  //       popupRef.current.remove();
  //       popupRef.current = null;
  //     }
  //   };
  // }, [device.device_id, flyToDeviceId, map]);

  useEffect(() => {
    if (isOpen && map) {
      popupRef.current.openOn(map);
    } else {
      if (map) {
        map.closePopup();
      }
    }
  }, [isOpen, map]);

  return (
    <Popup ref={popupRef}>
      <div className="flex flex-col gap-1 ">
        <div className="text-base font-medium ">{device.device_name}</div>
        {config?.showLocation ? (
          <div className="flex gap-1 items-center">
            <MapPinIcon width={18} />
            <div>
              {device.latitude.toFixed(6)}, {device.longitude.toFixed(6)}
            </div>
          </div>
        ) : null}

        <div className="flex flex-col mt-2">
          <div className="text-sm text-contentColor flex gap-1">
            <span className="font-bold">Internet Status:</span>
            <Badge size={"xs"} color={internetActive ? "green" : "red"}>
              {internetActive ? "Active" : "Down"}
            </Badge>
          </div>
        </div>

        <a
          href={
            showExample
              ? `/`
              : `${process.env.REACT_APP_DASHBOARDS_URL}/${org.id}/${proj.id}/device/${device.device_id}`
          }
          target="_blank"
          rel={"noreferrer noopenner"}
          className="!text-white mt-2 w-full px-2 py-1 rounded-md bg-blue-500 hover:bg-blue-600 text-center text-sm"
        >
          View Dashboard
        </a>
      </div>
    </Popup>
  );
};

interface IZoomToAreaProps {
  device: ILocationDevice;
}

const ZoomToArea: React.FC<IZoomToAreaProps> = ({ device }) => {
  const map = useMap();

  useEffect(() => {
    if (device) {
      // map.closePopup();

      map.flyTo({ lat: device.latitude, lng: device.longitude }, 15);
    }
  }, [device?.device_id, map]);

  return null;
};

export default React.memo(MapView);
