import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon
} from "@heroicons/react/24/outline";
import { formatDownloadUploadBytes } from "../../dash.helper";

interface WiFiInfoProps {
  upload: number;
  download: number;
}

const DataConsumption: React.FC<WiFiInfoProps> = ({ download, upload }) => {
  const {
    download: { unit: downloadUnit, value: downloadConverted },
    upload: { unit: uploadUnit, value: uploadConverted }
  } = formatDownloadUploadBytes(download, upload, 4);

  return (
    <div className="p-4 rounded-lg flex justify-around items-center h-full -mt-[34px]">
      <div>
        <div className="flex items-center gap-2">
          <ArrowDownCircleIcon width={28} color="#00AC47" />
          <div className="text-base font-medium">DOWNLOAD </div>
        </div>
        <div className="text-center text-5xl font-semibold ml-18">
          {downloadConverted.toFixed(1)}
          <span className="text-contentColorLight font-normal text-xl">
            {" "}
            {downloadUnit}
          </span>
        </div>
      </div>
      <div>
        <div className="flex items-center gap-2">
          <ArrowUpCircleIcon width={28} color="#1B65D4" />
          <div className="text-base font-medium">UPLOAD </div>
        </div>
        <div className="text-center text-5xl font-semibold ml-18">
          {uploadConverted.toFixed(1)}
          <span className="text-contentColorLight font-normal text-xl">
            {" "}
            {uploadUnit}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DataConsumption;
