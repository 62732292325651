export const GRID_COLS = {
  xxl: 5,
  xl: 4,
  lg: 4,
  md: 3,
  sm: 2,
  xs: 1,
  xxs: 1
};

export function generateLayout(panels, maxCols: number, resizeHandles) {
  const layout = [];

  let y = 0;

  const mapTypes = ["GEO_MAP", "MAP_LOCATION"];
  let x = 0;
  panels.forEach((panel) => {
    const isMapType =
      panel.panel_type === "GENERIC"
        ? mapTypes.includes(panel.definition.panel_type)
        : mapTypes.includes(panel.panel_type);

    let curW = 1,
      curH = 1;
    if (x + curW > maxCols) {
      x = 0;
      y++;
    }

    layout.push({
      i: panel.id,
      x: x,
      y: y,
      w: curW,
      h: curH,
      minW: 1,
      minH: 1,
      // maxW: maxCols,
      static: false,
      isResizable: true,
      resizeHandles: resizeHandles
    });
    x += curW;

    if (x > maxCols) {
      x = 0;
      y++;
    }
  });

  return layout;
}

export function formatBytes(bytes: number, rate = false, decimals = 2) {
  if (!+bytes) return { value: 0, unit: rate ? "bps" : "Bytes" };

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const rates = [
    "bps",
    "Kbps",
    "Mbps",
    "Gbps",
    "Tbps",
    "Pbps",
    "Ebps",
    "Zbps",
    "Ybps"
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    unit: rate ? rates[i] : sizes[i]
  };
}

export function formatDownloadUploadBytes(
  download: number,
  upload: number,
  decimals = 2
) {
  // Check if the inputs are not numbers or are falsy
  if (!+download || !+upload) {
    return {
      download: { value: 0, unit: "Bytes" },
      upload: { value: 0, unit: "Bytes" }
    };
  }

  // Constants
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;

  // Units in the metric system
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  // Determine the larger unit of the two values
  const largerUnitIndex = Math.max(
    Math.floor(Math.log(download) / Math.log(k)),
    Math.floor(Math.log(upload) / Math.log(k))
  );

  // Convert both values to the larger unit
  const downloadUnitIndex = Math.min(largerUnitIndex, sizes.length - 1);
  const uploadUnitIndex = Math.min(largerUnitIndex, sizes.length - 1);

  return {
    download: {
      value: parseFloat(
        (download / Math.pow(k, downloadUnitIndex)).toFixed(dm)
      ),
      unit: sizes[downloadUnitIndex]
    },
    upload: {
      value: parseFloat((upload / Math.pow(k, uploadUnitIndex)).toFixed(dm)),
      unit: sizes[uploadUnitIndex]
    }
  };
}

export function formatLineChartData(value, unit) {
  if (!unit) {
    return value;
  }

  if (unit === "BYTES") {
    const formattedBytes = formatBytes(value);
    return `${formattedBytes.value} ${formattedBytes.unit}`;
  }

  return value;
}

export function getDeviceConfigURL(deviceName: string) {
  return `https://${deviceName}.configure.production.scogo.in`;
}

export function getDeviceSSHURL(deviceName: string) {
  return `https://${deviceName}.terminal.production.scogo.in`;
}
