import { IScogoDeviceMetadata } from "@/interfaces";
import {
  Badge,
  Table,
  TableBody,
  TableCell,
  TableHeaderCell,
  TableRow
} from "@tremor/react";
import { useMemo } from "react";

interface IRouterInfoProps {
  metaData: IScogoDeviceMetadata;
}

const RouterInfo: React.FC<IRouterInfoProps> = ({ metaData }) => {
  const deviceMetaData: IScogoDeviceMetadata = metaData;

  const data = useMemo(() => {
    return [
      {
        label: "Make & Model",
        value: `${deviceMetaData?.device.make ?? "N/A"} ${
          deviceMetaData?.device.model ?? "N/A"
        }`
      },
      { label: "Type", value: deviceMetaData?.device.asset_type ?? "N/A" },
      {
        label: "Serial Number",
        value: (
          <Badge size={"sm"}>
            {deviceMetaData?.device.serial_number ?? "N/A"}
          </Badge>
        )
      },
      {
        label: "Installation Address",
        value: (
          <span>
            {deviceMetaData?.site.device_installation_address ?? "N/A"}
          </span>
        )
      },
      { label: "Edge Router OS", value: "v1.05.00" }
    ];
  }, [deviceMetaData]);

  return (
    <>
      <div className={`h-[90%] px-3 pb-3 overflow-y-auto w-full`}>
        <Table
          className="max-w-full"
          style={{
            maxWidth: "100%"
          }}
        >
          <TableBody className="">
            {data.map((item) => (
              <TableRow key={item.label}>
                <TableHeaderCell>{item.label}</TableHeaderCell>
                <TableCell className="whitespace-normal">
                  {item.value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default RouterInfo;
