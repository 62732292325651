const IPIcon = ({
  width = "18px",
  height = undefined,
  className = "",
  ...props
}) => {
  return (
    <svg
      className={`stroke-contentColor ${className}`}
      width={width}
      height={height ?? width}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      xmlSpace="preserve"
      {...props}
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          d="M2341 5109 c-472 -50 -928 -269 -1270 -610 -325 -326 -537 -754 -596
-1204 -22 -166 -20 -370 5 -497 93 -482 487 -1119 1155 -1868 241 -270 896
-925 925 -925 29 0 680 651 926 925 449 502 763 939 963 1340 152 306 211 521
211 770 0 708 -379 1381 -993 1762 -120 75 -326 172 -451 213 -271 89 -593
123 -875 94z m511 -609 c545 -116 962 -536 1083 -1090 22 -102 31 -346 16
-451 -85 -600 -515 -1066 -1101 -1194 -164 -36 -446 -31 -615 12 -397 99 -738
371 -920 732 -282 564 -142 1268 335 1678 217 187 448 289 765 337 73 11 343
-3 437 -24z"
        />
        <path
          d="M2680 3929 c-63 -5 -140 -12 -170 -16 l-55 -8 -3 -853 -2 -852 190 0
190 0 2 305 3 304 160 5 c256 6 408 59 534 184 107 107 152 224 152 397 1 215
-95 374 -276 461 -93 44 -168 63 -300 74 -127 11 -261 11 -425 -1z m471 -312
c98 -43 143 -115 143 -227 1 -132 -65 -221 -192 -260 -63 -20 -199 -27 -249
-14 l-23 6 0 253 0 254 23 5 c50 11 262 -1 298 -17z"
        />
        <path
          d="M1717 3924 c-4 -4 -7 -394 -7 -866 l0 -858 200 0 200 0 -2 863 -3
862 -191 3 c-104 1 -193 -1 -197 -4z"
        />
      </g>
    </svg>
  );
};

export default IPIcon;
