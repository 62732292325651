import { isEmpty } from "lodash";
import React, { useEffect, useId, useMemo, useRef, useState } from "react";
import {
  IDashboard,
  IDevice,
  IFleet,
  IGaugeDefination,
  ILineDefination,
  IPieDefinations
} from "../../interfaces";
import { useDashboardStore } from "../../store";
import DashboardUtilsHeader from "./components/dash-filter-header.component";
import PanelWrapper from "./dash-wrapper-panel.component";
import CarouselWrapper from "./dash-carousel-warpper.component";
import { Tooltip } from "react-tooltip";
import { CoreProps, Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { GRID_COLS, generateLayout } from "./dash.helper";
import { useGetDashboardPanels } from "@app/shared/hooks/get/dashboard-panels";
import ShowLoading from "@app/shared/components/loading.component";
import ShowError from "@app/shared/components/error.component";
import { Badge, Color } from "@tremor/react";
import { TDashPanel } from "@/store/dashboard/dashboard.store";
import Modal from "../shared/components/modal.component";
import { useGetBlueprints } from "../shared/hooks/get/blueprints";
import { IBlueprint } from "@/interfaces/blueprint.interface";
import { PortInfoTooltip } from "./components/graphs/ports-info.component";
import { WifiInfoTooltip } from "./components/graphs/wifi-info.component";
import dateService from "@/services/date.service";
import { LTEInfoTooltip } from "./components/graphs/lte-info.component";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface IDashboardDetails {
  device?: IDevice;
  fleet?: IFleet;
  dashboardToRender?: IDashboard;
  dashBlueprint?: IBlueprint;
  onAddPanel?: () => void;
  dashboardConfigs?: {
    hideAddPanel?: boolean;
    hideDelete?: boolean;
    hideEditLayout?: boolean;
  };
}

const timeFilterOptions = [
  {
    value: "TODAY",
    label: (
      <>
        Today
        <span className="text-xs">
          {" "}
          (
          {dateService
            .getMomentDate()
            .startOf("day")
            .add(1, "second")
            .format("Do MMMM 'YY")}{" "}
          - {dateService.getMomentDate().format("Do MMMM 'YY")})
        </span>
      </>
    ),
    startDate: dateService.getMomentDate().startOf("day").add(1, "second"),
    endDate: dateService.getMomentDate()
  },
  {
    value: "LAST_24_HOURS",
    label: (
      <>
        Last 24 Hours
        <span className="text-xs">
          {" "}
          (
          {dateService
            .getMomentDate()
            .subtract(24, "hours")
            .format("Do MMMM 'YY")}{" "}
          - {dateService.getMomentDate().format("Do MMMM 'YY")})
        </span>
      </>
    ),
    startDate: dateService.getMomentDate().subtract(24, "hours"),
    endDate: dateService.getMomentDate()
  },
  {
    value: "LAST_7",
    label: (
      <>
        Last 7 Days
        <span className="text-xs">
          {" "}
          (
          {dateService
            .getMomentDate()
            .subtract(7, "days")
            .format("Do MMMM 'YY")}{" "}
          - {dateService.getMomentDate().format("Do MMMM 'YY")})
        </span>
      </>
    ),
    startDate: dateService.getMomentDate().subtract(7, "days"),
    endDate: dateService.getMomentDate()
  },
  {
    value: "LAST_WEEK",
    label: (
      <>
        Last Week
        <span className="text-xs">
          {" "}
          (
          {dateService
            .getMomentDate()
            .subtract(1, "week")
            .startOf("week")
            .format("Do MMMM 'YY")}{" "}
          -
          {dateService
            .getMomentDate()
            .subtract(1, "week")
            .endOf("week")
            .format("Do MMMM 'YY")}
          )
        </span>
      </>
    ),
    startDate: dateService.getMomentDate().subtract(1, "week").startOf("week"),
    endDate: dateService.getMomentDate().subtract(1, "week").endOf("week")
  },
  {
    value: "LAST_30",
    label: (
      <>
        Last 30 Days
        <span className="text-xs">
          {" "}
          (
          {dateService
            .getMomentDate()
            .subtract(30, "days")
            .format("Do MMMM 'YY")}
          - {dateService.getMomentDate().format("Do MMMM 'YY")})
        </span>
      </>
    ),
    startDate: dateService.getMomentDate().subtract(30, "days"),
    endDate: dateService.getMomentDate()
  },
  {
    value: "LAST_MONTH",
    label: (
      <>
        Last Month
        <span className="text-xs">
          {" "}
          (
          {dateService
            .getMomentDate()
            .subtract(1, "months")
            .startOf("month")
            .format("Do MMMM 'YY")}
          -
          {dateService
            .getMomentDate()
            .subtract(1, "months")
            .endOf("month")
            .format("Do MMMM 'YY")}
          )
        </span>
      </>
    ),
    startDate: dateService
      .getMomentDate()
      .subtract(1, "months")
      .startOf("month"),
    endDate: dateService.getMomentDate().subtract(1, "months").endOf("month")
  },
  {
    value: "MONTH_TO_DATE",
    label: (
      <>
        Month to Date
        <span className="text-xs">
          {" "}
          ({dateService
            .getMomentDate()
            .startOf("month")
            .format("Do MMMM 'YY")}{" "}
          -{dateService.getMomentDate().format("Do MMMM 'YY")})
        </span>
      </>
    ),
    startDate: dateService.getMomentDate().startOf("month"),
    endDate: dateService.getMomentDate()
  }
];

const Details: React.FC<IDashboardDetails> = ({
  device,
  fleet,
  dashboardConfigs,
  dashboardToRender: activeDashboard,
  dashBlueprint,
  onAddPanel
}) => {
  const wrapperRef = useRef(null);
  const panelErrors = useRef({});
  const errorToastTimer = useRef(null);
  const timeFilterTooltipRef = useRef(null);

  const [panels, setPanels] = useState([]);
  const timeFilterTooltipId = useId();

  const [editDashboard, expandedItemId, setExpandedItemId] = useDashboardStore(
    (state) => [
      state.editingLayout,
      state.expandedItemId,
      state.setExpandedItemId
    ]
  );

  const [
    dashboards,
    carousels,
    setCarousels,
    clearCreatePanelState,
    zoomLevel
  ] = useDashboardStore((state) => [
    state.dashboards,
    state.carousels,
    state.setCarousels,
    state.clearCreatePanelState,
    state.zoomLevel
  ]);

  const sortPanels = (obj: any) => {
    const sortedObj = {};
    const rowKeys = Object.keys(obj);

    if (rowKeys.length) {
      rowKeys.forEach((row) => {
        const sortedPanels = obj[row].sort(
          (a, b) => a.definition.panel_position - b.definition.panel_position
        );

        sortedObj[row] = sortedPanels;
      });
    }
    return { ...sortedObj };
  };

  // if rendered in project level dashboard, fetch panels from that dashboard
  const { isLoading: isDashPanelsLoading, error } = useGetDashboardPanels(
    device || dashBlueprint ? "" : activeDashboard?.id,
    {},
    (dashboardPanels) => {
      if (device) return;
      if (dashboardPanels?.length) {
        let carouselObj = {};
        let panelsArr = [];
        dashboardPanels.forEach((panel) => {
          if (panel.definition.arrangement) {
            if (panel.definition.arrangement.carousel in carouselObj) {
              carouselObj[panel.definition.arrangement.carousel].push(panel);
            } else {
              carouselObj[panel.definition.arrangement.carousel] = [panel];
            }
          } else {
            panelsArr.push(panel);
          }
        });
        if (!isEmpty(carouselObj)) {
          setCarousels(sortPanels(carouselObj));
        }
        if (panelsArr.length) {
          const newState = {
            currentBreakpoint: "lg",
            compactType: "vertical",
            resizeHandles: ["se"],
            mounted: true,
            // generate layouts according to the current breakpoint,
            // and merge them with the saved layouts
            layouts: {
              ...Object.keys(GRID_COLS).reduce((acc, cur) => {
                acc[cur] = generateLayout(panelsArr, GRID_COLS[cur], ["se"]);
                return acc;
              }, {}),
              ...activeDashboard?.meta_data?.layouts
            }
          };

          setLayoutState(newState);
          setPanels(panelsArr);
        }
      } else {
        setPanels([]);
      }
    }
  );

  // TODO: Render panels applied to devices separately
  // const { isLoading: isDevicePanelsLoading } = useGetDeviceBlueprints(
  //   selectedFleet.id,
  //   deviceId,
  //   {},
  //   (blueprintPanels) => {
  //     if (!blueprintPanels) setPanels([]);
  //     const panelsArr: TDashPanel[] =
  //       convertBlueprintPanelsToDashboardPanels(blueprintPanels);

  //     setPanels(panelsArr);
  //   }
  // );

  // For device dashboards
  const { data: dashBlueprintPanels, isLoading: isBlueprintPanelsLoading } =
    useGetBlueprints({
      // typescript type check
      blueprint_id:
        dashBlueprint?.definition &&
        "blueprint_ids" in dashBlueprint?.definition
          ? dashBlueprint?.definition?.blueprint_ids.join(",")
          : ""
    });

  const dashboardLayout = useMemo(() => {
    if (!dashBlueprint?.meta_data?.layouts || !dashBlueprintPanels?.length)
      return {};

    const newLayouts = { ...dashBlueprint.meta_data?.layouts };

    return newLayouts;
  }, [dashBlueprint?.meta_data?.layouts, dashBlueprintPanels]);

  useEffect(() => {
    if (!dashBlueprintPanels?.length) {
      setPanels([]);
      return;
    }

    const panelsArr: TDashPanel[] = convertBlueprintPanelsToDashboardPanels(
      dashBlueprintPanels ?? []
    );

    setLayoutState((cur) => ({ ...cur, layouts: dashboardLayout }));
    setPanels(panelsArr);
  }, [dashBlueprintPanels, dashboardLayout]);

  useEffect(() => {
    if (activeDashboard?.id) {
      clearCreatePanelState();
    }
  }, [activeDashboard?.id, clearCreatePanelState]);

  useEffect(() => {
    if (!wrapperRef.current) return;
    wrapperRef.current.style.zoom = `${zoomLevel}%`;
  }, [zoomLevel]);

  useEffect(() => {
    let timer = errorToastTimer.current;

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const renderCarouselPanels = () => {
    const carouselKeys = Object.keys(carousels);
    return carouselKeys.map((carousel) => (
      <CarouselWrapper title={carousel} panels={carousels[carousel]} />
    ));
  };

  const handlePanelError = (
    panelId: string,
    panelTitle: string,
    error: string
  ) => {
    panelErrors.current[panelId] = { error, panelTitle };

    // batch the errors into one toast to avoid spamming the user with toasts
    // if (Object.keys(panelErrors.current).length === 1) {
    //   errorToastTimer.current = setTimeout(() => {
    //     if (Object.keys(panelErrors.current).length)
    //       toast.error(
    //         <div>
    //           <span className="text-sm font-bold mb-1 block">
    //             There were some errors fetching the data of following panels:{" "}
    //           </span>
    //           <div className="flex flex-col">
    //             {Object.keys(panelErrors.current).map((panelId) => (
    //               <div className="flex items-center" key={panelId}>
    //                 <span className="text-xs font-medium whitespace-nowrap  ">
    //                   {panelErrors.current[panelId].panelTitle}:
    //                 </span>
    //                 <span className="text-xs ml-2">
    //                   {panelErrors.current[panelId].error}
    //                 </span>
    //               </div>
    //             ))}
    //           </div>
    //         </div>
    //       );
    //     panelErrors.current = {};
    //     clearTimeout(errorToastTimer.current);
    //   }, 500);
    // }
  };

  const [layoutState, setLayoutState] = useState({
    currentBreakpoint: "lg",
    compactType: "vertical",
    resizeHandles: ["se"],
    mounted: false,
    layouts: {
      ...Object.keys(GRID_COLS).reduce((acc, cur) => {
        acc[cur] = generateLayout(panels, GRID_COLS[cur], ["se"]);
        return acc;
      }, {}),
      ...dashboardLayout
    }
  });

  const onBreakpointChange: (Breakpoint) => void = (breakpoint) => {
    setLayoutState((curState) => ({
      ...curState,
      currentBreakpoint: breakpoint
    }));
  };

  const memoizedPanels = useMemo(() => {
    // if (!layoutState.layouts[layoutState.currentBreakpoint]?.length) return;
    return panels.map(
      (panel: ILineDefination | IPieDefinations | IGaugeDefination) => {
        return (
          <div
            key={panel.isBlueprint ? panel.blueprint_id : panel.id}
            data-grid={layoutState.layouts[layoutState.currentBreakpoint].find(
              (el) =>
                el.i === (panel.isBlueprint ? panel.blueprint_id : panel.id)
            )}
            className={`${editDashboard ? "select-none" : ""} ${
              !editDashboard && "hide-resize"
            }`}
          >
            <PanelWrapper
              panel={panel}
              panels={panels}
              timeFilterTooltipId={timeFilterTooltipId}
              setPanels={setPanels}
              device={device}
              fleet={fleet}
              dashboard={activeDashboard}
              key={panel.isBlueprint ? panel.blueprint_id : panel.id}
              handlePanelError={handlePanelError}
            />
          </div>
        );
      }
    );
  }, [
    activeDashboard,
    timeFilterTooltipId,
    device,
    editDashboard,
    layoutState.currentBreakpoint,
    layoutState.layouts,
    panels,
    fleet
  ]);

  // need to do this because for some reason RGL calls the onLayoutChange callback with
  // a layout object that arranges all the panels into a single column
  useEffect(() => {
    setLayoutState((curState) => ({
      ...curState,
      layouts: {
        ...Object.keys(GRID_COLS).reduce((acc, cur) => {
          acc[cur] = generateLayout(panels, GRID_COLS[cur], ["se"]);
          return acc;
        }, {}),
        ...dashboardLayout
      }
    }));
  }, [dashboardLayout]);

  const expandedPanel = useMemo(
    () =>
      panels.find(
        (p) => p.id === expandedItemId || p.blueprint_id === expandedItemId
      ),
    [panels, expandedItemId]
  );

  if (isDashPanelsLoading || isBlueprintPanelsLoading) {
    return <ShowLoading />;
  }

  if (error) {
    return <ShowError />;
  }
  if (!device && !dashboards.length && !dashBlueprint) {
    return null;
  }

  return (
    <main className="w-full h-auto py-4 bg-background-layer1 overflow-y-auto lg:px-6 sm:px-4">
      <DashboardUtilsHeader
        dashboard={activeDashboard}
        hideAddPanel={dashboardConfigs?.hideAddPanel}
        hideDelete={dashboardConfigs?.hideDelete}
        hideEditLayout={dashboardConfigs?.hideEditLayout}
        addPanel={() => {}}
        layouts={layoutState.layouts}
        setLayouts={setLayoutState}
        panels={panels}
      />
      <div ref={wrapperRef} className="w-full py-4">
        {!isEmpty(carousels) && renderCarouselPanels()}
        <div className="flex flex-wrap gap-4 w-full h-full">
          {memoizedPanels?.length ? (
            <ResponsiveGridLayout
              className="layout w-full"
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={GRID_COLS}
              rowHeight={260}
              width={1200}
              isDraggable={editDashboard}
              isResizable={editDashboard}
              layouts={layoutState.layouts}
              onBreakpointChange={onBreakpointChange}
              resizeHandles={["se", "sw"]}
              measureBeforeMount={false}
              useCSSTransforms={layoutState.mounted}
              compactType={layoutState.compactType as CoreProps["compactType"]}
              preventCollision={!layoutState.compactType}
              onLayoutChange={(layout, layouts) => {
                if (!layout.length) return;

                // save the current layout to all the breakpoints, let the library handle the arrangement
                const newLayouts = Object.keys(GRID_COLS).reduce(
                  (acc, cur) => {
                    if (!layout.length) return acc;
                    acc[cur] = layout;
                    return acc;
                  },
                  {}
                );
                if (Object.keys(newLayouts).length) {
                  setLayoutState((curState) => ({
                    ...curState,
                    layouts: newLayouts
                  }));
                }
              }}
            >
              {memoizedPanels}
            </ResponsiveGridLayout>
          ) : null}
        </div>
      </div>

      {/* <Modal
        open={!!expandedPanel}
        setOpen={(x: boolean) => setExpandedItemId(x ? expandedItemId : null)}
      >
        <div className="flex flex-col gap-4 p-6 w-[95vw] h-[95vh] bg-background text-contentColor">
          <h1 className="text-lg font-bold">{expandedPanel?.title}</h1>

          {expandedPanel ? (
            <PanelWrapper
              dashboard={activeDashboard}
              panel={expandedPanel}
              panels={panels}
              setPanels={setPanels}
              device={device}
              fleet={fleet}
              handlePanelError={handlePanelError}
            />
          ) : null}
        </div>
      </Modal> */}

      {/* Need to render these AFTER all panel components are rendered */}
      {/* else they show up below neighboring panels. */}
      <Tooltip
        id="panel-error-tooltip"
        className="border border-red-500"
        style={{
          zIndex: 100000,
          backgroundColor: "#F87171",
          color: "#fff"
        }}
      />
      <Tooltip
        id="panel-description"
        className="z-[100] transform text-white bg-blue-500 border-blue-700"
        variant="light"
        border={"1px solid rgb(29 78 216 / var(--tw-border-opacity))"}
        style={{
          zIndex: 100,
          backgroundColor: "rgb(59 130 246 / var(--tw-bg-opacity))",
          color: "white"
        }}
      />
      <Tooltip
        id="panel-content-info"
        className="z-[100] shadow-md rounded-md"
        variant="light"
      />
      {/* Table Tooltip */}
      <Tooltip
        id={`tooltip-badge-hover`}
        className="z-20 transform"
        variant="light"
        border={"1px solid black"}
        render={({ activeAnchor }) => {
          const labels = activeAnchor
            ?.getAttribute("data-tooltip-labels")
            ?.split(",");
          const values = activeAnchor
            ?.getAttribute("data-tooltip-values")
            ?.split(",");
          const colorsProp = activeAnchor?.getAttribute("data-tooltip-colors");
          const colors = colorsProp ? JSON.parse(colorsProp) : {};

          return (
            <div className="flex flex-col gap-1">
              {labels?.map((label, i) => (
                <Badge
                  size="xs"
                  key={i}
                  color={colors[label] as Color}
                  className={`text-xs`}
                >
                  <span style={{ fontSize: "0.2rem !important" }}>
                    {label}: {values[i]}
                  </span>
                </Badge>
              ))}
            </div>
          );
        }}
      />

      <PortInfoTooltip device={device} />

      <WifiInfoTooltip />

      <LTEInfoTooltip />

      <Tooltip
        id="panel-custom-time-filter"
        className="z-[100] transform shadow-md"
        variant="light"
        style={{
          zIndex: 100
        }}
      />
      <Tooltip
        id={"panelTimeFilterSelector" + timeFilterTooltipId}
        ref={timeFilterTooltipRef}
        variant="light"
        clickable
        openOnClick
        closeEvents={{ click: true }}
        opacity={1}
        className="z-[100] transform shadow-md"
        render={({ activeAnchor }) => (
          <div className="p-2">
            <h4 className="font-medium mb-2">Select Time Filters </h4>

            <div className="flex flex-col rounded-md border border-background-layer3">
              {timeFilterOptions.map((opt) => (
                <div
                  key={opt.value}
                  className={`flex flex-col gap-1 p-2 cursor-pointer hover:bg-background-layer2 `}
                  onClick={() => {
                    const panelId = activeAnchor.getAttribute("data-panel-id");

                    const newPanels = [...panels].map((panel) => ({
                      ...panel,
                      customTimeFilters:
                        (panel.isBlueprint ? panel.blueprint_id : panel.id) ===
                        panelId
                          ? {
                              start: opt.startDate,
                              end: opt.endDate
                            }
                          : { ...panel.customTimeFilters }
                    }));

                    setPanels(newPanels);
                    timeFilterTooltipRef.current?.close();
                  }}
                >
                  <h5 className="font-medium">{opt.label}</h5>
                </div>
              ))}
            </div>
          </div>
        )}
      ></Tooltip>
    </main>
  );
};

export default Details;

function convertBlueprintPanelsToDashboardPanels(
  blueprintPanels: IBlueprint[]
) {
  const panelsArr: TDashPanel[] = [];

  // convert blueprint panels to standard panels
  blueprintPanels.forEach((bp) => {
    // typescript type check
    if ("type" in bp.definition) {
      const panel: TDashPanel = {
        id: bp.source_id,
        blueprint_id: bp.id,
        isBlueprint: true,
        title: bp.definition.title,
        panel_description: bp.definition.description,
        panel_type: bp.definition.type,
        definition: bp.definition.panel_definition,
        data_config: bp.data_config?.config,
        dashboard_id: "",
        project_id: bp.project_id,
        org_id: "",
        created_at: bp.created_at
      };
      panelsArr.push(panel);
    }
  });

  return panelsArr;
}
