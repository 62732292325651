import { Fragment, HTMLAttributes, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

interface IModalProps {
  open: boolean;
  className?: string;
  disableClickOutside?: boolean;
  setOpen: (val: boolean) => void;
}

const Modal: React.FC<HTMLAttributes<HTMLDivElement> & IModalProps> = ({
  open,
  className,
  disableClickOutside,
  setOpen,
  children
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        static
        as="div"
        className={`relative`}
        style={{ zIndex: 99 }}
        initialFocus={cancelButtonRef}
        onClose={() => {
          if (!disableClickOutside) {
            setOpen(false);
          }
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={` relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ${className}`}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
