import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { IDevice, IDeviceResponse } from "@interfaces/fad.interface";

export const getDevices = async (projectId: string, orgId: string, params) => {
  const { data, ok, meta } = await networkService.get<IDeviceResponse>(
    `projects/${projectId}/devices/`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return { devices: data.devices, meta };
  } else return null;
};

export const useGetDevices = (
  params: any = {},
  successCb: ((data: IDevice[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getDevices", selectedProject.id, user.selectedOrg?.id, params],
    async () => {
      const devicesResponse = await getDevices(
        selectedProject.id,
        user.selectedOrg?.id,
        params
      );

      successCb && successCb(devicesResponse.devices);

      return devicesResponse;
    },
    {
      enabled: params?.hasOwnProperty("device_id")
        ? !!selectedProject.id && !!user.selectedOrg?.id && !!params?.device_id
        : params?.hasOwnProperty("data_point_definition_id")
        ? !!selectedProject.id &&
          !!user.selectedOrg?.id &&
          !!params?.data_point_definition_id
        : !!selectedProject.id && !!user.selectedOrg?.id
    }
  );

  return res;
};
