import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { useDashboardStore } from "@store/index";
//graph helpers
import {
  IDashboard,
  IDevice,
  IFleet,
  IGaugeDefination,
  ILineDefination,
  IPieDefinations,
  IScogoDeviceMetadata
} from "@interfaces/index";
import GraphHeader from "./components/graphs/dash-graph-header.component";
import {
  generateGraphData,
  getInfographicSum
} from "./components/graphs/graph.helper";
//graphs
import Gauge from "./components/gauge/gauge";
import { PieChart, LineChart, BarChart } from "./components/graphs";
import MapView from "./components/map/dash-map-component";
import TempMeter from "./components/gauge/temp-meter";
import NumericInfoGraph from "./components/graphs/dash-numeric-graph.component";
import dateService from "@services/date.service";
import HeatMap from "./components/graphs/heatmap.component";
import { PanelErrorProvider } from "../shared/utils/dash-panel-error.context";
import TableComponent from "./components/table/dash-table.component";
import { useGetDashboardPanelData } from "@app/shared/hooks/get/panel-data";
import { DASH_PANEL_TYPE } from "@/interfaces/dashboard-panel.interface";
import { useGetBlueprintPanelData } from "../shared/hooks/get/blueprint-panel-data";
import { useGetDevices } from "../shared/hooks/get/devices";
import PortsInfo, {
  IPortInfo
} from "./components/graphs/ports-info.component";
import WiFiInfo from "./components/graphs/wifi-info.component";
import LTEInfo from "./components/graphs/lte-info.component";
import TextPanel from "./components/graphs/dash-text.component";
import DataConsumption from "./components/graphs/data-consumption.component";
import SpeedTest from "./components/graphs/speed-test.component";
import PacketLossGraph from "./components/graphs/packet-loss.component";
import RouterInfo from "./components/graphs/router-info.component";
import DeviceStatus from "./components/graphs/device-status.component";
import { useGetShadowRealtime } from "../shared/hooks/get/shadow-realtime";
import DashMapDeviceLocationComponent from "./components/map/dash-map-device-location.component";
import { useGetDeviceMetaData } from "../shared/hooks/get/device-meta-data";
import { useLocation, useParams } from "react-router-dom";
import { useGetFleets } from "../shared/hooks/get/fleets";
import { useGetTags } from "../shared/hooks/get/tags";
import useGeoMapStore from "@/store/dashboard/geo-map.store";
import { useGetFleetBlueprintPanelData } from "../shared/hooks/get/fleet-blueprint-panel-data";
import { formatNumber } from "../shared/utils/dashboard.utils";
import { formatBytes } from "./dash.helper";

interface IPanelWrapperProps {
  panel: ILineDefination | IPieDefinations | IGaugeDefination;
  panels?;
  setPanels?;
  timeFilterTooltipId?: string;
  inCarousel?: boolean;
  device?: IDevice;
  fleet?: IFleet;
  dashboard?: IDashboard;
  handlePanelError?: (
    panelId: string,
    panelTitle: string,
    error: string
  ) => void;
}

const realtimePanels = [
  DASH_PANEL_TYPE.LINE_CHART,
  DASH_PANEL_TYPE.BAR_CHART,
  DASH_PANEL_TYPE.PIE_CHART,
  DASH_PANEL_TYPE.GAUGE_1,
  DASH_PANEL_TYPE.GAUGE_2,
  DASH_PANEL_TYPE.NUMERIC,
  DASH_PANEL_TYPE.GEO_MAP
];

const SHADOW_PANELS = ["PORTS_INFO", "WIFI_INFO", "LTE_INFO"];
const TIMESTAMP_FORMAT = "DD/MM hh:mm:ss A";

const PanelWrapper: React.FC<IPanelWrapperProps> = React.forwardRef<
  HTMLDivElement,
  IPanelWrapperProps
>(
  (
    {
      panel,
      panels,
      setPanels,
      timeFilterTooltipId,
      device,
      fleet,
      dashboard: activeDashboard,
      inCarousel = false,
      handlePanelError
    },
    ref
  ) => {
    const location = useLocation();
    const urlParams = useParams();

    const isFleetDashboard = location.pathname.includes("fleet");

    const [panelError, setPanelError] = useState<string>("");
    const [activeFilter, editingLayout] = useDashboardStore((state) => [
      state.activeFilter,
      state.editingLayout
    ]);

    const [setGeoMapDevices, setAllGeoMapDevices] = useGeoMapStore((state) => [
      state.setGeoMapDevices,
      state.setAllGeoMapDevices
    ]);

    // For Geo Map panel
    const [selectedFleetsForMapFilter, setSelectedFleetsForMapFilter] =
      useState(fleet ? [{ value: fleet.id, label: fleet.fleet_name }] : []);

    const [selectedTagsForMapFilter, setSelectedTagsForMapFilter] = useState(
      []
    );

    const boundingBox = useRef([
      72.99440494134234, 19.08157106006555, 73.01979520183855,
      19.056713982534205
    ]);

    const duration = activeFilter.span
      ? formatDuration(activeFilter.span)
      : null;

    let start = activeFilter.span
      ? dateService
          .getCurrentUTCDate()
          .subtract(parseInt(activeFilter.span), duration)
          .format()
      : activeFilter.start;
    if (
      panel.panel_type === "LINE_CHART" &&
      panel.definition.meta?.type === "Info"
    ) {
      start = dateService.getCurrentUTCDate().subtract(24, "hours").format();
    }

    let stop = activeFilter.span
      ? dateService.getCurrentUTCDate().format()
      : activeFilter.stop;

    let params = {
      start: panel.customTimeFilters?.start?.utc().format() ?? start,
      stop: panel.customTimeFilters?.end?.utc().format() ?? stop
    };
    if (activeFilter?.span) {
      params["span"] = true;
    }

    if (panel.panel_type === DASH_PANEL_TYPE.GEO_MAP) {
      params["bounding_box"] = boundingBox.current.join(",");
      params["fleet_ids"] = selectedFleetsForMapFilter
        .map((opt) => opt.value)
        .join(",");

      params["tag_ids"] = selectedTagsForMapFilter
        .map((opt) => opt.value)
        .join(",");

      delete params["start"];
      delete params["stop"];
    } else if (realtimePanels.includes(panel.panel_type)) {
      params["where"] = JSON.stringify([
        {
          timestamp: {
            lte: activeFilter.span
              ? dateService.getCurrentUTCDate().format()
              : activeFilter.stop
          }
        },
        {
          timestamp: {
            gte: activeFilter.span
              ? dateService
                  .getCurrentUTCDate()
                  .subtract(parseInt(activeFilter.span), duration)
                  .format()
              : activeFilter.start
          }
        }
      ]);
    }

    const { data: shadow } = useGetShadowRealtime(
      device?.fleet_id,
      device?.id
    );

    if (activeDashboard.meta_data?.iface) {
      params["iface"] =
        activeDashboard.meta_data?.iface === "wan1"
          ? shadow?.wan1Mapping
          : shadow?.wan2Mapping;
    }

    const { data: _panelData, error } = useGetDashboardPanelData(
      activeDashboard.id,
      device?.id ||
        SHADOW_PANELS.includes(panel.definition.panel_type) ||
        isFleetDashboard
        ? ""
        : panel.id,
      true,
      params,
      {
        blockErrorToast: true,
        noLoader: true,
        refetchOnParamsChange: panel.panel_type === DASH_PANEL_TYPE.GEO_MAP,
        enabled: !editingLayout
      }
    );

    const { data: deviceMetaData } = useGetDeviceMetaData(
      device?.fleet_id,
      device?.id
    );

    // for GEO_MAP panel,
    const { data: deviceWithShadows } = useGetDevices({
      shadow_format: "json",
      device_id:
        panel.panel_type === DASH_PANEL_TYPE.GEO_MAP
          ? _panelData?.devices?.map((d) => d.device_id).join(",")
          : ""
    });

    useEffect(() => {
      setGeoMapDevices(deviceWithShadows?.devices ?? []);
    }, [deviceWithShadows?.devices]);

    useEffect(() => {
      if (panel.panel_type === DASH_PANEL_TYPE.GEO_MAP) {
        setAllGeoMapDevices(_panelData?.devices ?? []);
      }
    }, [_panelData?.devices]);

    const { data: fleets, isLoading: isFleetsLoading } = useGetFleets();
    const { data: tags, isLoading: isTagsLoading } = useGetTags();

    const { data: blueprintPanelData, error: blueprintPanelError } =
      useGetBlueprintPanelData(
        device?.fleet_id,
        device?.id,
        panel.blueprint_id,
        !editingLayout,
        params,
        { blockErrorToast: true, noLoader: true }
      );

    const { data: fleetBlueprintPanelData, error: fleetBlueprintPanelErorr } =
      useGetFleetBlueprintPanelData(
        urlParams["fleet_id"],
        panel.blueprint_id,
        true,
        params,
        {
          blockErrorToast: true,
          noLoader: true,
          refetchOnParamsChange: panel.panel_type === DASH_PANEL_TYPE.GEO_MAP,
          enabled: !!urlParams["fleet_id"]
        }
      );

    // for GEO_MAP panel,
    const { data: fleetBlueprintDeviceWithShadows } = useGetDevices({
      shadow_format: "json",
      device_id:
        panel.panel_type === DASH_PANEL_TYPE.GEO_MAP
          ? fleetBlueprintPanelData?.devices?.map((d) => d.device_id).join(",")
          : ""
    });

    useEffect(() => {
      setGeoMapDevices(fleetBlueprintDeviceWithShadows?.devices ?? []);
    }, [fleetBlueprintDeviceWithShadows?.devices]);

    useEffect(() => {
      if (panel.panel_type === DASH_PANEL_TYPE.GEO_MAP) {
        setAllGeoMapDevices(fleetBlueprintPanelData?.devices ?? []);
      }
    }, [fleetBlueprintPanelData?.devices]);

    useEffect(() => {
      const _err = (error ||
        blueprintPanelError ||
        fleetBlueprintPanelErorr) as { message: string };
      if (_err) {
        handlePanelError &&
          handlePanelError(panel.id, panel.title, _err.message);
        setPanelError(_err.message);
      }

      return () => {};
    }, [
      error,
      blueprintPanelError,
      fleetBlueprintPanelErorr,
      handlePanelError,
      panel.id,
      panel.title
    ]);

    const onChangeBoundingBox = (bBox: []) => {
      if (bBox.length) {
        boundingBox.current = bBox;
      }
    };

    const renderNoDataPanel = () => {
      return (
        <div
          ref={ref}
          className={`flex flex-col w-full h-full bg-background text-contentColor rounded-md`}
          key={panel.id}
        >
          <GraphHeader
            panel={panel}
            timeFilterTooltipId={timeFilterTooltipId}
            panels={panels}
            setPanels={setPanels}
            disableZoom
          />
          <div className="flex w-full flex-grow justify-center items-center">
            <h1 className="text-base">No Data Available</h1>
          </div>
        </div>
      );
    };

    return (
      <PanelErrorProvider value={{ panelError, setPanelError }}>
        <RenderPanel
          forwardRef={ref}
          panel={panel}
          panels={panels}
          setPanels={setPanels}
          fleetsData={{ fleets, isLoading: isFleetsLoading }}
          tagsData={{ tags, isLoading: isTagsLoading }}
          selectedFleetsForMapFilter={selectedFleetsForMapFilter}
          setSelectedFleetsForMapFilter={setSelectedFleetsForMapFilter}
          selectedTagsForMapFilter={selectedTagsForMapFilter}
          setSelectedTagsForMapFilter={setSelectedTagsForMapFilter}
          shadow={shadow}
          timeFilterTooltipId={timeFilterTooltipId}
          deviceMetaData={deviceMetaData}
          panelData={
            _panelData || blueprintPanelData || fleetBlueprintPanelData
          }
          renderNoDataPanel={renderNoDataPanel}
          onChangeBoundingBox={onChangeBoundingBox}
          inCarousel={inCarousel}
          boundingBox={boundingBox.current}
          geoMapDevicesWithShadows={deviceWithShadows?.devices ?? []}
        />
      </PanelErrorProvider>
    );
  }
);

const RenderPanel = ({
  panel,
  panels,
  setPanels,
  fleetsData,
  tagsData,
  selectedFleetsForMapFilter,
  setSelectedFleetsForMapFilter,
  selectedTagsForMapFilter,
  setSelectedTagsForMapFilter,
  timeFilterTooltipId,
  panelData,
  deviceMetaData,
  shadow,
  forwardRef,
  renderNoDataPanel,
  onChangeBoundingBox,
  inCarousel,
  boundingBox,
  geoMapDevicesWithShadows
}) => {
  const [geoMapDevices, allGeoMapDevices] = useGeoMapStore((state) => [
    state.geoMapDevices,
    state.allGeoMapDevices
  ]);

  if (panel.panel_type === DASH_PANEL_TYPE.PIE_CHART) {
    let data = [],
      category = "count",
      index = "value";

    if (panelData?.options) {
      data = panelData?.options;
      if (!panelData.options?.length) {
        return renderNoDataPanel();
      }
    } else if (Array.isArray(panelData) && panelData?.length) {
      data = panelData;
      category = "count";
      index = "name";
    }
    if (data.length) {
      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <PieChart
            data={data}
            category={category}
            index={index}
            title={
              Object.hasOwn(panel.data_config, "source")
                ? index
                : panel.data_config["group"][0]
            }
            variant="donut"
            key={panel.id}
            colors={panel.definition.options.label_colors}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
  }

  if (
    panel.panel_type === DASH_PANEL_TYPE.GAUGE &&
    panelData?.current_value?.value &&
    panelData?.max_value?.value
  ) {
    if (panel.definition.options["gauge-type"] === "v") {
      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <TempMeter
            max={panel.definition.options.max_display}
            min={panel.definition.options.min_display}
            title={panel.title}
            minValue={panelData?.current_value?.value}
            maxValue={panelData?.max_value?.value}
            units={panel.definition.options.unit}
            key={panel.id}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
    return (
      <InnerPanelWrapper
        timeFilterTooltipId={timeFilterTooltipId}
        panels={panels}
        setPanels={setPanels}
        panel={panel}
      >
        <Gauge
          max={panel.definition.options.max_display}
          min={panel.definition.options.min_display}
          title={panel.title}
          minValue={panelData?.current_value?.value}
          maxValue={panelData?.max_value?.value}
          units={panel.definition.options.unit}
          key={panel.id}
          zoom={panel.definition.zoom}
        />
      </InnerPanelWrapper>
    );
  }

  if (
    panel.panel_type === DASH_PANEL_TYPE.GAUGE &&
    Array.isArray(panelData) &&
    panelData?.length
  ) {
    if (panel.definition.options["gauge-type"] === "v") {
      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <TempMeter
            max={panel.definition.options.max_display}
            min={panel.definition.options.min_display}
            title={panel.title}
            minValue={panelData[0]?.current_value}
            maxValue={panelData[0]?.max_value}
            units={panel.definition.options.unit}
            key={panel.id}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
    return (
      <InnerPanelWrapper
        timeFilterTooltipId={timeFilterTooltipId}
        panels={panels}
        setPanels={setPanels}
        panel={panel}
      >
        <Gauge
          max={panel.definition.options.max_display}
          min={panel.definition.options.min_display}
          title={panel.title}
          minValue={panelData[0]?.current_value}
          maxValue={panelData[0]?.max_value}
          units={panel.definition.options.unit}
          key={panel.id}
          zoom={panel.definition.zoom}
        />
      </InnerPanelWrapper>
    );
  }

  if (
    panel.panel_type === DASH_PANEL_TYPE.LINE_CHART &&
    panel.definition.meta?.type === "Info"
  ) {
    const infoKeys = Object.keys(panelData ?? {});
    const filter = panel.definition.default_range?.start;
    if (infoKeys?.length && !_.isEmpty(panelData)) {
      const resultantValue = getInfographicSum(panelData[infoKeys[0]], filter);
      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <NumericInfoGraph
            title={panel.title}
            info={resultantValue}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
  }

  if (panel.panel_type === DASH_PANEL_TYPE.LINE_CHART) {
    let categories: string[],
      chartData: any,
      index: "value" | "time",
      dataAvailable: boolean;

    let colors = panel.definition.options.label_colors;

    if (panel.data_config.source) {
      const labels = Object.keys(panel.data_config?.source ?? {});
      const dataObj = { value: "" };
      let labelToParam = {};
      labels.forEach((s) => {
        labelToParam[s] = panel.data_config.source[s]["data-point-param"];
        dataObj[labelToParam[s]] = "";
      });
      const { isData, processedData } = generateGraphData(
        panelData || {},
        labels,
        dataObj
      );
      dataAvailable = isData;
      categories = labels;
      chartData = processedData;
      index = "value";
    } else {
      categories = panel.data_config?.select
        .filter((col) => col.alias !== "time")
        .map((col) => col.alias ?? col.param);

      chartData = panelData?.reduce((acc, cur) => {
        let dataItem = {};

        if (panel.definition.options.paramToLabels) {
          Object.entries(panel.definition.options.paramToLabels).forEach(
            ([key, value]: [string, string]) => {
              dataItem[value] = cur[key];
            }
          );
        } else {
          dataItem = { ...cur };
        }

        dataItem["time"] = dateService
          .convertUTCDateToUserTimezone(cur.time)
          .format(TIMESTAMP_FORMAT);

        acc.push(dataItem);

        return acc;
      }, []);

      if (panel.definition.options.paramToLabels) {
        categories = [];
        colors = [];

        Object.keys(panel.definition.options.paramToLabels).forEach(
          (param) => {
            categories.push(panel.definition.options.paramToLabels[param]);
            if (panel.definition.options.paramToColors) {
              colors.push(panel.definition.options.paramToColors[param]);
            }
          }
        );
      }

      index = "time";
      dataAvailable = panelData?.length;
    }

    if (dataAvailable) {
      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <LineChart
            chartdata={chartData}
            categories={categories}
            labels={panel.definition?.options?.labels ?? undefined}
            colors={colors}
            index={index}
            title={panel.title}
            key={panel.id}
            valueFormatter={
              panel.definition.options.paramUnit === "BYTES"
                ? (val) => {
                    const { value, unit } = formatBytes(val);
                    return `${value} ${unit}`;
                  }
                : panel.definition.options.paramUnit === "Kbps"
                ? (val) => {
                    // val is in Kbps to
                    const { value, unit } = formatBytes(val * 1000 * 8, true);
                    return `${value} ${unit}`;
                  }
                : undefined
            }
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
  }

  if (panel.panel_type === DASH_PANEL_TYPE.BAR_CHART) {
    let categories: string[],
      chartData: any,
      index: "value" | "time",
      dataAvailable: boolean;
    let colors = panel.definition.options.bar_colors;

    if (panel.data_config.source) {
      const sources = Object.keys(panel.data_config.source);
      const dataObj = { value: "" };
      let definations = {};
      sources.forEach((s) => {
        definations[s] = panel.data_config.source[s]["data-point-param"];
        dataObj[definations[s]] = "";
      });
      const { isData, processedData } = generateGraphData(
        panelData || {},
        sources,
        dataObj
      );
      dataAvailable = isData;
      categories = sources;
      chartData = processedData;
      index = "value";
    } else {
      categories = panel.data_config?.select
        .filter((col) => col.alias !== "time")
        .map((col) => col.alias ?? col.param);
      chartData = panelData?.map((data) => ({
        ...data,
        time: dateService
          .convertUTCDateToUserTimezone(data.time)
          .format(TIMESTAMP_FORMAT)
      }));
      index = "time";
      dataAvailable = panelData?.length;
    }

    if (dataAvailable) {
      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <BarChart
            orientation={panel.definition.options.orientation}
            chartdata={chartData}
            categories={categories}
            colors={colors}
            index={index}
            title={panel.title}
            key={panel.id}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
  }

  if (panel.panel_type === DASH_PANEL_TYPE.NUMERIC) {
    if (Array.isArray(panelData) && panelData?.length) {
      const resultantValue =
        panelData[0].value * (panel.definition.factor || 1);
      let minVal, maxVal;

      if (panelData[0].min) {
        minVal = panelData[0].min * (panel.definition.factor || 1);
      }

      if (panelData[0].max) {
        maxVal = panelData[0].max * (panel.definition.factor || 1);
      }

      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <NumericInfoGraph
            title={panel.title}
            info={resultantValue}
            unit={panel.definition.options?.display_unit}
            zoom={panel.definition.zoom}
            minValue={minVal ?? null}
            maxValue={maxVal ?? null}
            increasingBad={panel.definition?.increasingBad}
            hideArrow={!!panel.definition.hideArrow}
          />
        </InnerPanelWrapper>
      );
    } else {
      const infoKeys = Object.keys(panelData || {});
      if (infoKeys?.length && !_.isEmpty(panelData)) {
        const resultantValue = panelData[infoKeys[0]].value;
        return (
          <InnerPanelWrapper
            timeFilterTooltipId={timeFilterTooltipId}
            panels={panels}
            setPanels={setPanels}
            panel={panel}
          >
            <NumericInfoGraph
              title={panel.title}
              info={resultantValue}
              unit={panel.definition.options?.display_unit}
              zoom={panel.definition.zoom}
              minValue={panelData[infoKeys[0]]?.min_value ?? undefined}
              maxValue={panelData[infoKeys[0]]?.max_value ?? undefined}
              hideArrow={!!panel.definition.hideArrow}
            />
          </InnerPanelWrapper>
        );
      }
    }
  }

  if (panel.panel_type === DASH_PANEL_TYPE.GEO_MAP) {
    if (panel.definition?.device_location) {
      const meta = deviceMetaData as IScogoDeviceMetadata;
      const [lat, lng] = meta?.site?.device_latitude_longitude
        ? meta?.site?.device_latitude_longitude?.split(",")?.map(parseFloat)
        : [0, 0];
      const locationDevice = meta?.site
        ? {
            address: meta?.site?.device_installation_address,
            lat,
            lng
          }
        : undefined;

      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <DashMapDeviceLocationComponent
            device={{ ...locationDevice, ...meta?.device }}
          />
        </InnerPanelWrapper>
      );
    } else {
      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <MapView
            fleetsData={fleetsData}
            tagsData={tagsData}
            selectedFleetsForMapFilter={selectedFleetsForMapFilter}
            setSelectedFleetsForMapFilter={setSelectedFleetsForMapFilter}
            selectedTagsForMapFilter={selectedTagsForMapFilter}
            setSelectedTagsForMapFilter={setSelectedTagsForMapFilter}
            geoMapDevicesWithShadows={geoMapDevicesWithShadows}
            devices={panelData?.devices || []}
            definition={panel.definition}
            clusters={panelData?.clusters || []}
            bounding_box={boundingBox}
            title={panel.title}
            key={panel.id}
            onChangeBoundingBox={onChangeBoundingBox}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
  }

  if (panel.panel_type === DASH_PANEL_TYPE.HEATMAP) {
    if (Array.isArray(panelData) && panelData?.length) {
      const transformedData = {
        [panel.definition.options.param?.value]: panelData.map((d) => {
          // FIXME: due to some problems with migration, "value" was not set as an alias
          // this covers new heatmaps plus old ones with the wrong config
          const value =
            d.value || d[panel.definition.options.param?.value] || 0;

          const modifiedRow = {
            ...d,
            value: {
              [panel.definition.options.aggregationMode]: value
            },
            time: dateService
              .convertUTCDateToUserTimezone(d.bucket || d.time)
              .toISOString()
          };

          if (panel.definition.options.gteValueMapping) {
            let label;

            for (
              let i = 0;
              i < panel.definition.options.gteValueMapping?.length;
              ++i
            ) {
              const obj: { value: number; label: string; color: string } =
                panel.definition.options.gteValueMapping[i];

              if (value >= obj.value) {
                label = {
                  label: obj.label,
                  color: obj.color
                };

                break;
              }
            }

            modifiedRow["label"] = {
              [panel.definition.options.aggregationMode]: label
            };
          } else {
            modifiedRow["label"] = {
              [panel.definition.options.aggregationMode]: undefined
            };
          }

          return modifiedRow;
        })
      };

      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <HeatMap
            title={panel.title}
            configValues={panel.definition.options}
            data={transformedData}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    } else if (
      panelData &&
      Object.keys(panelData).length &&
      panel.definition.options.param &&
      panelData[panel.definition.options.param?.value]?.length
    ) {
      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <HeatMap
            title={panel.title}
            configValues={panel.definition.options}
            data={panelData}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
  }

  if (panel.panel_type === "GENERIC") {
    if (panel.definition.panel_type === "NUMERIC") {
      const infoKeys = Object.keys(panelData || {});
      if (infoKeys?.length && !_.isEmpty(panelData)) {
        const resultantValue =
          panelData[0].count * (panel.definition.factor || 1);
        return (
          <InnerPanelWrapper
            timeFilterTooltipId={timeFilterTooltipId}
            panels={panels}
            setPanels={setPanels}
            panel={panel}
          >
            <NumericInfoGraph
              title={panel.title}
              info={resultantValue}
              unit={panel.definition.options?.display_unit}
              zoom={panel.definition.zoom}
              minValue={panelData[0].minValue}
              maxValue={panelData[0].maxValue}
              hideArrow={!!panel.definition.hideArrow}
            />
          </InnerPanelWrapper>
        );
      }
    } else if (panel.definition.panel_type === "ERRORS") {
      let value = 0;

      if (panelData?.[0]) {
        if (panelData?.[0].txPackets != null) {
          value =
            ((panelData[0].txErrors + panelData[0].rxErrors) /
              (panelData[0].txPackets + panelData[0].rxPackets)) *
            100;
        }
      }

      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <NumericInfoGraph
            title={panel.title}
            info={value}
            unit={panel.definition.options?.display_unit}
            zoom={panel.definition.zoom}
            minValue={null}
            maxValue={null}
            hideArrow={!!panel.definition.hideArrow}
          />
        </InnerPanelWrapper>
      );
    } else if (panel.definition.panel_type === "DEVICE_COUNT") {
      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <NumericInfoGraph
            title={panel.title}
            info={allGeoMapDevices?.length ?? 0}
            unit={panel.definition.options?.display_unit}
            zoom={panel.definition.zoom}
            minValue={null}
            maxValue={null}
            hideArrow={!!panel.definition.hideArrow}
          />
        </InnerPanelWrapper>
      );
    } else if (panel.definition.panel_type === "PORT_INFO") {
      const links = [];

      if (deviceMetaData?.link1) {
        links.push(deviceMetaData?.link1);
      }

      if (deviceMetaData?.link2) {
        links.push(deviceMetaData?.link2);
      }

      const interfaces = ["lan1", "lan2", "lan3", "lan4"];

      const portsInfo = interfaces.map((iface, ind) => {
        let portInfo: IPortInfo = {
          name: `Port-${ind + 1}`,
          enabled: false,
          physicalPort: 0,
          configuredAs: "",
          portStatus: "CONNECTED",
          internetStatus: "UP",
          privateIPv4: "",
          privateIPv6: "",
          publicIP: "",
          isLanPort: false
        };

        let shadowIface = shadow?.ifaces?.find(
          (sIface) => sIface.iface === iface
        );

        switch (iface) {
          case shadow?.wan1Mapping:
            portInfo.configuredAs = "WAN-1";
            portInfo.isLanPort = false;
            break;

          case shadow?.wan2Mapping:
            portInfo.configuredAs = "WAN-2";

            portInfo.isLanPort = false;
            break;

          default:
            break;
        }

        if (shadow?.lanMappings?.includes(iface)) {
          portInfo.isLanPort = true;
          portInfo.configuredAs = "LAN";
        }

        portInfo.physicalPort = ind + 1;
        portInfo.ISP = shadowIface?.backhaul ?? "Not Available";
        portInfo.enabled = shadowIface?.enabled;
        portInfo.privateIPv4 = shadowIface?.ip;
        portInfo.privateIPv6 = shadowIface?.ip6;
        portInfo.publicIP = shadowIface?.publicIp;
        portInfo.internetStatus = shadowIface?.internetActive ? "UP" : "DOWN";
        portInfo.portStatus = shadowIface?.connected
          ? "CONNECTED"
          : "DISCONNECTED";

        return portInfo;
      });

      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <PortsInfo portInfos={portsInfo} />
        </InnerPanelWrapper>
      );
    } else if (panel.definition.panel_type === "WIFI_INFO") {
      const wifiInterface = shadow?.ifaces?.find(
        (iface) => iface.iface === shadow?.wifiMapping
      );

      const wifi5Interface = shadow?.ifaces?.find(
        (iface) => iface.iface === shadow?.wifi5Mapping
      );

      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <WiFiInfo
            wifiInfos={[
              {
                name: "2.5 GHz",
                configured: wifiInterface?.enabled,
                connectedDevices: 4,
                noiseLevel: "24db",
                packetLoss: 420,
                signalQuality: "GOOD",
                signalStrength: "-42db",
                ssid: "Scogo_2.5GHz"
              },
              {
                name: "5 GHz",
                configured: wifi5Interface?.enabled,
                connectedDevices: 0,
                noiseLevel: "Not Available",
                packetLoss: 0,
                signalQuality: "Not Available",
                signalStrength: "Not Available",
                ssid: "Not Available"
              }
            ]}
          />
        </InnerPanelWrapper>
      );
    } else if (panel.definition.panel_type === "LTE_INFO") {
      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <LTEInfo
            lteInfo={{
              name: "LTE",
              provider: "N/A",
              signal: "POOR",
              status: "NA",
              configured: false
            }}
          />
        </InnerPanelWrapper>
      );
    } else if (panel.definition.panel_type === "DATA_CONSUMPTION") {
      let download = 0,
        upload = 0;
      if (panelData?.length) {
        download = panelData?.[0]["rxBytes"];
        upload = panelData?.[0]["txBytes"];
      }
      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <DataConsumption download={download} upload={upload} />
        </InnerPanelWrapper>
      );
    } else if (panel.definition.panel_type === "TEXT") {
      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <TextPanel lines={panel.definition.line_definition ?? []} />
        </InnerPanelWrapper>
      );
    } else if (panel.definition.panel_type === "SPEED_TEST") {
      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <SpeedTest data={panelData?.length ? panelData?.[0] : undefined} />
        </InnerPanelWrapper>
      );
    } else if (
      panel.definition.panel_type === "PACKET_LOSS" &&
      panelData?.[0] &&
      panelData?.[0]?.txPackets !== null
    ) {
      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <PacketLossGraph data={panelData[0]} />
        </InnerPanelWrapper>
      );
    } else if (panel.definition.panel_type === "ROUTER_INFO") {
      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <RouterInfo metaData={deviceMetaData} />
        </InnerPanelWrapper>
      );
    } else if (panel.definition.panel_type === "DEVICE_STATUS") {
      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <DeviceStatus />
        </InnerPanelWrapper>
      );
    } else if (panel.definition.panel_type === "DEVICES_METADATA_TABLE") {
      const data = [];

      geoMapDevices.forEach((device: IDevice) => {
        const meta = device?.meta_data as IScogoDeviceMetadata;

        data.push({
          device_id: device.id,
          device_name: device.device_name,
          end_customer: meta?.site?.end_customer_name,
          isp_1: meta?.link1?.isp_name,
          isp_2: meta?.link2?.isp_name,
          state: "N/A",
          city: "N/A",
          device_status: {
            connected: device.connected,
            last_seen: device.last_seen
          },
          device_dashboard: device.id
        });
      });

      return (
        <InnerPanelWrapper
          timeFilterTooltipId={timeFilterTooltipId}
          panels={panels}
          setPanels={setPanels}
          panel={panel}
        >
          <TableComponent
            title={panel.title}
            data={data}
            panelColumnDefs={panel.definition.columnState}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    } else {
      // FIXME: add else if for table
      if (
        panelData &&
        Object.keys(panelData).length &&
        panel.definition.columnState
      ) {
        return (
          <InnerPanelWrapper
            timeFilterTooltipId={timeFilterTooltipId}
            panels={panels}
            setPanels={setPanels}
            panel={panel}
          >
            <TableComponent
              title={panel.title}
              data={panelData || []}
              panelColumnDefs={panel.definition.columnState}
              zoom={panel.definition.zoom}
            />
          </InnerPanelWrapper>
        );
      }
    }
  }

  return renderNoDataPanel();
};

const InnerPanelWrapper = ({
  timeFilterTooltipId,
  panel,
  panels,
  setPanels,
  children
}) => {
  return (
    <div
      className={`${
        panel.definition?.backgroundColor
          ? "bg-" + panel.definition?.backgroundColor + "-200"
          : ""
      } w-full h-full bg-background rounded-md`}
    >
      <GraphHeader
        disableActions
        timeFilterTooltipId={timeFilterTooltipId}
        panel={panel}
        panels={panels}
        setPanels={setPanels}
      />
      <div
        className={`h-[92%]`}
        style={{
          zoom: panel.definition.zoom
            ? panel.definition.zoom.toString() + "%"
            : "100%"
        }}
      >
        {children}
      </div>
    </div>
  );
};

function formatDuration(duration) {
  let _duration: any;
  if (duration.includes("s")) _duration = "seconds";
  else if (duration.includes("m")) _duration = "minutes";
  else _duration = "hours";

  return _duration;
}

export default PanelWrapper;
