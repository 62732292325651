import LTEIcon from "@/app/shared/icons/lte.icon";
import { WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import { Badge } from "@tremor/react";
import { Tooltip } from "react-tooltip";

interface LTEInfoProps {
  lteInfo: {
    name: string;
    configured: boolean;
    provider: string;
    status: "UP" | "DOWN" | "NA"; // NA = Not Available
    signal: "POOR" | "GOOD" | "BEST";
  };
}

const LTEInfo: React.FC<LTEInfoProps> = ({ lteInfo }) => {
  return (
    <>
      <div
        className={`grid grid-cols-1 grid-rows-1 h-[90%] place-items-center`}
      >
        <div className="flex flex-col items-center font-bold cursor-pointer h-full mt-8">
          {lteInfo.name}
          <LTEIcon
            data-tooltip-id={`lte-info-tooltip`}
            data-tooltip-lteInfo={JSON.stringify(lteInfo)}
            className={`h-[60%] w-auto ${
              lteInfo.status === "UP"
                ? "stroke-green-500 fill-green-500"
                : "stroke-gray-500 fill-gray-500"
            }`}
          />
        </div>
      </div>
    </>
  );
};

export const LTEInfoTooltip = () => {
  return (
    <Tooltip
      id={`lte-info-tooltip`}
      variant="light"
      opacity={1}
      className="z-[100] shadow-lg "
      place="right"
      render={({ activeAnchor }) => {
        const portInfoJSON = activeAnchor?.getAttribute(
          "data-tooltip-lteInfo"
        );
        const lteInfo: LTEInfoProps["lteInfo"] = JSON.parse(
          portInfoJSON ?? "{}"
        );

        if (!lteInfo) return null;

        return (
          <div>
            <h2 className="font-bold text-lg mb-2">{lteInfo.name}</h2>

            <span className="flex gap-3 mb-1">
              <label className="text-contentColorLight flex gap-2">
                <WrenchScrewdriverIcon width={18} />
                Status:
              </label>
              <Badge color={lteInfo.configured ? "blue" : "slate"}>
                {lteInfo.configured ? "Configured" : "Not Configured"}
              </Badge>
            </span>
            {lteInfo.configured ? (
              <>
                {lteInfo.provider?.trim() ? (
                  <span className="flex gap-1 mb-1">
                    <label className="font-medium">ISP:</label>
                    <span>{lteInfo.provider}</span>
                  </span>
                ) : null}

                {lteInfo.signal?.trim() ? (
                  <span className="flex gap-1 mb-1">
                    <label className="font-medium">Signal:</label>
                    <span>
                      <Badge
                        color={
                          lteInfo.signal === "BEST"
                            ? "green"
                            : lteInfo.signal === "POOR"
                            ? "red"
                            : "yellow"
                        }
                      >
                        {lteInfo.signal}
                      </Badge>
                    </span>
                  </span>
                ) : null}

                <span className="flex gap-1 mb-1">
                  <label className="font-medium">Status:</label>
                  <span>
                    <Badge
                      color={
                        lteInfo.status === "UP"
                          ? "green"
                          : lteInfo.status === "DOWN"
                          ? "red"
                          : "slate"
                      }
                    >
                      {lteInfo.status === "UP"
                        ? "Connected / UP"
                        : lteInfo.status === "DOWN"
                        ? "Disconnected / Down"
                        : "Not Connected"}
                    </Badge>
                  </span>
                </span>
              </>
            ) : null}
          </div>
        );
      }}
    ></Tooltip>
  );
};

export default LTEInfo;
