import dateService from "@/services/date.service";
import { useFleetAndDevicesStore } from "@/store";
import { Badge } from "@tremor/react";
import { useMemo } from "react";
import { Tooltip } from "react-tooltip";

interface WiFiInfoProps {}

const DeviceStatus: React.FC<WiFiInfoProps> = () => {
  const device = useFleetAndDevicesStore((state) => state.selectedDevice);

  const deviceStatus = useMemo(
    () =>
      device?.connected === null ? (
        <Badge size="xl" color="yellow">
          {"Never Reached"}
        </Badge>
      ) : device?.connected === false ? (
        <Badge size="xl" color="red">
          {"Offline"}
        </Badge>
      ) : (
        <Badge size="xl" color="green">
          {"Online"}
        </Badge>
      ),
    [device?.connected]
  );

  const calculatedUptime = useMemo(() => {
    if (!device?.connected) {
      return {
        days: 0,
        hours: 0,
        minutes: 0
      };
    }

    const lastSeenData = dateService.convertDateStrToMoment(device?.last_seen);
    const today = dateService.getMomentDate();

    const days = today.diff(lastSeenData, "days");
    today.subtract(days, "days");

    const hours = today.diff(lastSeenData, "hours");
    today.subtract(hours, "hours");

    const minutes = today.diff(lastSeenData, "minutes");

    return {
      days,
      hours,
      minutes
    };
  }, [device?.connected, device?.last_seen]);

  return (
    <>
      <div className={`h-[90%] flex flex-col justify-around items-center`}>
        <div
          data-tooltip-id="device-status-panel-tooltip"
          data-tooltip-html={`<b>Uptime:</b> Time active since last restart.${
            device?.last_seen
              ? `<br />
              <span style="margin:4px 0 0 0; font-size:12px;">
                <b>Last ${
                  device?.connected ? "Restart" : "Active"
                }:</b> ${dateService
                  .convertDateStrToMoment(device?.last_seen)
                  .format("Do MMMM 'YY, hh:mm:ss A")}
              </span>
                  `
              : ""
          }`}
          data-tooltip-place="bottom"
          className={`flex items-end gap-2`}
        >
          {device?.connected !== null ? (
            <div className="flex gap-2 items-end mt-4">
              <span className="text-6xl text-contentColor">
                {calculatedUptime.days}
              </span>
              <span className="text-xl mb-1 text-contentColorLight">Days</span>{" "}
              <span className="text-3xl  text-contentColor">
                {calculatedUptime.hours}
              </span>
              <span className="text-sm mb-1 text-contentColorLight">
                Hours
              </span>{" "}
              <span className="text-3xl  text-contentColor">
                {calculatedUptime.minutes}
              </span>
              <span className="text-sm mb-1 text-contentColorLight">Mins</span>{" "}
            </div>
          ) : (
            "Never Online"
          )}
        </div>
        <div
          data-tooltip-id="device-status-panel-tooltip"
          data-tooltip-html="<b>Reachability:</b> Indicates if the device is reachable"
          className={`flex flex-col items-center gap-1`}
        >
          {deviceStatus ?? null}
        </div>
      </div>
      <Tooltip
        id="device-status-panel-tooltip"
        variant="light"
        className="z-[100] shadow-lg "
        style={{
          zIndex: 100
        }}
      />
    </>
  );
};

export default DeviceStatus;
