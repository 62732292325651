import React, { useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";

import { useDashboardStore } from "../../store";
import { IDashboard, IDevice, IFleet } from "../../interfaces";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ShowError from "../shared/components/error.component";
import {
  ChevronDownIcon,
  ComputerDesktopIcon,
  WrenchIcon
} from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import { useGetDashboards } from "@app/shared/hooks/get/dashboards";
import ShowLoading from "@app/shared/components/loading.component";
import { PanelDetails } from ".";
import RJ45Icon from "../shared/icons/rj45.icon";
import { IBlueprint } from "@/interfaces/blueprint.interface";
import { Tooltip } from "react-tooltip";
import { Badge } from "@tremor/react";
import dateService from "@/services/date.service";
import { getDeviceConfigURL, getDeviceSSHURL } from "./dash.helper";

interface IAllDashboardsProps {
  deviceId?: string;
  dashboardBlueprints?: IBlueprint[];
  renderAs: "PROJECT_DASHBOARD" | "DEVICE_DASHBOARD" | "FLEET_DASHBOARD";
  device?: IDevice;
  fleet?: IFleet;
  configs?: {
    hideCreateBlueprints: boolean;
    hideCreateDashboard: boolean;
  };
  helpText?: string;
  customDashboardHeader?: string;
}
const AllDashboards: React.FC<IAllDashboardsProps> = ({
  configs,
  device,
  fleet,
  renderAs,
  helpText,
  dashboardBlueprints,
  customDashboardHeader
}) => {
  const [
    dashboards,
    setActiveDashboard,
    setDashboards,
    setPanels,
    setCarousels
  ] = useDashboardStore((state) => [
    state.dashboards,
    state.setActiveDashboard,
    state.setDashboards,
    state.setPanels,
    state.setCarousels
  ]);

  const { isLoading, error } = useGetDashboards(
    {},
    renderAs === "PROJECT_DASHBOARD",
    (dashboards) => {
      if (dashboards?.length) {
        setDashboards(dashboards);
      } else {
        setPanels([]);
        setCarousels({});
        setDashboards([]);
        setActiveDashboard({} as IDashboard);
      }
    }
  );

  useEffect(() => {
    if (!dashboardBlueprints) {
      return;
    }
    const blueprintToDashboards: IDashboard[] =
      dashboardBlueprints?.map((dashBlueprint) => ({
        created_at: dashBlueprint.created_at,
        id: dashBlueprint.id,
        dashboard_description: dashBlueprint.definition.description,
        dashboard_name: dashBlueprint.name,
        meta_data: dashBlueprint.meta_data,
        project_id: dashBlueprint.project_id,
        org_id: "",
        dashBlueprint
      })) ?? [];

    setDashboards(blueprintToDashboards ?? []);

    return () => {};
  }, [dashboardBlueprints, setDashboards]);

  const [primaryDash, otherDash] = useMemo(() => {
    let primaryDash: IDashboard,
      otherDash: IDashboard[] = [];

    dashboards.forEach((dashboard) =>
      dashboard.meta_data?.primary_dashboard
        ? (primaryDash = dashboard)
        : otherDash.push(dashboard)
    );

    if (!primaryDash && otherDash.length) {
      primaryDash = otherDash[0];
      otherDash.splice(0, 1);
    }

    otherDash.sort(
      (a, b) => (a.meta_data?.order ?? 0) - (b.meta_data?.order ?? 0)
    );

    return [primaryDash, otherDash];
  }, [dashboards]);

  if (error) {
    return <ShowError />;
  }

  if (isLoading) {
    return <ShowLoading />;
  }

  return (
    <>
      <div className="bg-background border-b lg:px-8 sm:px-6 border-b-background-layer2">
        {/* <div className="flex flex-row gap-4 items-end pt-4 pb-4 w-full border-b-background-layer2 border-b">
          <img
            src={`${process.env.PUBLIC_URL}/logos/scogo-dark.png`}
            alt="scogo logo"
            className="max-w-[180px]"
          />
          <span className="text-xl mb-1">SER Dashboards</span>
        </div> */}
        <div className="relative flex flex-col justify-bet">
          {renderAs === "PROJECT_DASHBOARD" ? (
            <div className="flex flex-col justify-between items-start pt-4 pb-4 w-full">
              <h4 className="text-xl font-semibold text-contentColor">
                {primaryDash?.dashboard_name}
              </h4>
            </div>
          ) : renderAs === "DEVICE_DASHBOARD" ? (
            <div className="flex flex-row justify-between items-center pt-4 pb-4 w-full">
              <div>
                <h6 className="text-sm text-contentColorLight">Device</h6>
                <h4 className="text-xl font-semibold text-contentColor">
                  {device?.device_name}
                </h4>
              </div>
              {device?.connected === null ? (
                <Badge data-tooltip-content="" size="lg" color="yellow">
                  {"Never Connected"}
                </Badge>
              ) : device?.connected === false ? (
                <Badge
                  data-tooltip-id="disconnected-tooltip"
                  data-tooltip-place="bottom"
                  data-tooltip-content={`Last seen:
                ${dateService.convertUTCToLocalDate(device?.last_seen)}`}
                  size="lg"
                  color="red"
                >
                  {"Disconnected"}
                </Badge>
              ) : (
                <Badge data-tooltip-content="" size="lg" color="green">
                  {"Connected"}
                </Badge>
              )}
              <div className="flex mr-2">
                <a
                  href={getDeviceConfigURL(device?.device_name)}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="bg-[#11616c] hover:bg-[#4a7980] border rounded-r-none border-[#11616c] px-3 py-2 rounded-md text-white whitespace-nowrap items-center flex gap-2"
                >
                  <WrenchIcon width={20} />
                  Config
                </a>
                <a
                  href={getDeviceSSHURL(device?.device_name)}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="bg-[#f1700e] hover:bg-[#e0975e] border rounded-l-none border-l-0 border-[#f1700e] px-3 py-2 rounded-md text-white whitespace-nowrap items-center flex gap-2"
                >
                  <ComputerDesktopIcon width={20} />
                  SSH
                </a>
              </div>
            </div>
          ) : renderAs === "FLEET_DASHBOARD" ? (
            <div className="flex flex-row justify-between items-center pt-4 pb-4 w-full">
              <div>
                <h6 className="text-sm text-contentColorLight">Fleet</h6>
                <h4 className="text-xl font-semibold text-contentColor">
                  {fleet?.fleet_name}
                </h4>
              </div>
              {primaryDash?.dashboard_name}
            </div>
          ) : null}
          <div className="relative bg-background flex flex-row justify-start items-center mt-1 w-full">
            {error ? (
              <ShowError />
            ) : dashboards.length > 6 ? (
              <React.Fragment>
                <div className="flex relative mr-10">
                  {/* {renderCreateBlueprintButton()} */}
                  {/* {renderCreateButton()} */}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="flex flex-row justify-start items-center">
                  {/* {renderTabList("w-30")} */}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <div className="px-6">
        {primaryDash ? (
          <>
            <Outlet context={{ dashboard: primaryDash }} />
            <PanelDetails
              device={device}
              fleet={fleet}
              dashboardToRender={primaryDash}
              dashBlueprint={
                renderAs === "DEVICE_DASHBOARD" ||
                renderAs === "FLEET_DASHBOARD"
                  ? primaryDash?.dashBlueprint
                  : undefined
              }
              dashboardConfigs={{
                hideAddPanel: true,
                hideDelete: true,
                hideEditLayout: true
              }}
            />
          </>
        ) : null}

        <div className="flex flex-col">
          {otherDash.map((dashboard) => (
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex text-xl w-full gap-4 ${
                      open ? "mb-0" : "mb-4"
                    } items-center rounded-md bg-background px-4 py-2 text-left font-medium hover:bg-background-layer2 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75`}
                  >
                    <RJ45Icon width="34px" />
                    {dashboard.dashboard_name}{" "}
                    <ChevronDownIcon
                      width={24}
                      className={`transform transition-transform duration-100 ${
                        open ? "rotate-180" : "rotate-0"
                      }`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className={"bg-background-layer2"}>
                    <Outlet context={{ dashboard: dashboard }} />
                    <PanelDetails
                      key={dashboard.id}
                      device={device}
                      fleet={fleet}
                      dashboardConfigs={{
                        hideAddPanel: true,
                        hideDelete: true,
                        hideEditLayout: true
                      }}
                      dashBlueprint={
                        renderAs === "DEVICE_DASHBOARD" ||
                        renderAs === "FLEET_DASHBOARD"
                          ? dashboard?.dashBlueprint
                          : undefined
                      }
                      dashboardToRender={dashboard}
                    />
                  </Disclosure.Panel>{" "}
                </>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
      {/* Outlet for all routes!*/}
      <Outlet />
      <Tooltip id="helpText" />
    </>
  );
};

export default React.memo(AllDashboards);
