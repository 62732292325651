import { arc } from "d3-shape";
import {
  getGaugeLinesCoords,
  getGaugeDistCoords,
  getGaugeLineCoord,
  getMinLinearAngle,
  getGradientSteps,
  getEndAngle
} from "./gauge-helper";
import React from "react";
import { IGauge } from "@interfaces/index";
import { formatNumber } from "@/app/shared/utils/dashboard.utils";

/* 
    Component: Circular Gauge
    Props:  {
        min : limitimg minimum value [ number ]
        max : limitimg maximum value [ number ]
        minValue : value to plot left(min) part of gauge ragning betwenn min & max [ number ] 
        maxValue : value to plot right(max) part of gauge ragning betwenn min & max[ number ]
        units : unit for values [ string ] { e.g. C for celcius}
        maxScales : bool to give maximized width & height to UI cards 
    }
    Author: Aaditya Kashid
*/

const Gauge: React.FC<IGauge> = ({
  minValue = 121,
  maxValue = 170,
  min = 0,
  max = 240,
  units = "C",
  showExample = false
}) => {
  const backgroundArc = arc<void, void>()
    .innerRadius(5.5)
    .outerRadius(8.5)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 2)();

  const outerArc = arc<void, void>()
    .innerRadius(9)
    .outerRadius(9.2)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 2)();

  const minAngle = getMinLinearAngle(
    min,
    max,
    -Math.PI / 2,
    Math.PI / 2,
    minValue
  );
  const gradientMinSpets = getGradientSteps(10, "#1B65D4", "#43D981");

  const maxAngle = getMinLinearAngle(
    max,
    min,
    Math.PI / 2,
    -Math.PI / 2,
    maxValue
  );
  const gradientMaxSpets = getGradientSteps(
    10,
    "#FE6A67",
    "rgba(255, 83, 80, 0.6)"
  );

  const filledMin = arc<void, void>()
    .innerRadius(5.5)
    .outerRadius(8.5)
    .startAngle(-Math.PI / 2)
    .endAngle(minAngle)();

  const filledMax = arc<void, void>()
    .innerRadius(5.5)
    .outerRadius(8.5)
    .startAngle(Math.PI / 2)
    .endAngle(maxAngle)();

  const endMinAngle = getEndAngle(
    -Math.PI / 2,
    Math.PI / 2,
    -Math.PI,
    0,
    minAngle
  );
  const endMaxAngle = getEndAngle(Math.PI / 2, -Math.PI / 2, 0, -Math.PI, max);

  const gaugeWhiteLines = getGaugeLinesCoords(
    12,
    0,
    0,
    8,
    1.7,
    endMinAngle,
    endMaxAngle
  );
  const gaugeBlueLines = getGaugeLinesCoords(
    12,
    0,
    0,
    8,
    0.5,
    endMinAngle,
    endMaxAngle
  );
  const gaugeDist = getGaugeDistCoords(
    12,
    0,
    0,
    7,
    endMinAngle,
    endMaxAngle,
    min,
    max
  );

  const gaugeMinLine = getGaugeLineCoord(endMinAngle, 0, 0, 4.7, 3.8);
  const gaugeMaxLine = getGaugeLineCoord(endMaxAngle, 0, 0, 4.7, 3.8);

  const gaugeImage = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="90%"
      height={showExample ? "300" : "80%"}
      viewBox="-10 -10 20 10"
    >
      <defs>
        <linearGradient
          id="Gauge__minGradient"
          gradientUnits="userSpaceOnUse"
          x1="-10"
          x2="10"
          y2="0"
        >
          {gradientMinSpets.map((color, index) => (
            <stop
              key={color}
              stopColor={color}
              offset={`${index / (gradientMinSpets.length - 1)}`}
            />
          ))}
        </linearGradient>
        <linearGradient
          id="Gauge__maxGradient"
          gradientUnits="userSpaceOnUse"
          x1="-10"
          x2="10"
          y2="0"
        >
          {gradientMaxSpets.map((color, index) => (
            <stop
              key={color}
              stopColor={color}
              offset={`${index / (gradientMaxSpets.length - 1)}`}
            />
          ))}
        </linearGradient>
      </defs>
      <path
        d={outerArc.replaceAll(",", " ")}
        className="fill-contentColorLight"
      />
      <path d={backgroundArc.replaceAll(",", " ")} fill="#D6E6FD" />
      <path
        d={filledMin.replaceAll(",", " ")}
        fill="url(#Gauge__minGradient)"
      />
      {/* <path
        d={filledMax.replaceAll(",", " ")}
        fill="url(#Gauge__maxGradient)"
      /> */}

      {gaugeWhiteLines.map((line, index) => (
        <line
          key={index}
          x1={line.ptOne.x}
          y1={line.ptOne.y}
          x2={line.ptTwo.x}
          y2={line.ptTwo.y}
          stroke="white"
          strokeWidth=".1"
        />
      ))}
      {gaugeBlueLines.map((line, index) => (
        <line
          key={index}
          x1={line.ptOne.x}
          y1={line.ptOne.y}
          x2={line.ptTwo.x}
          y2={line.ptTwo.y}
          stroke={line.color}
          strokeWidth=".1"
        />
      ))}
      {gaugeDist.points.map((pt, index) => (
        <text
          key={index}
          x={pt.x}
          y={pt.y}
          style={{ fontSize: "0.75", fontWeight: "500" }}
          fontSize="0.75px"
          dominantBaseline="middle"
          textAnchor="middle"
          fill={pt.color}
        >
          {formatNumber(gaugeDist.values[index])}
        </text>
      ))}

      <line
        x1={gaugeMinLine.ptOne.x}
        y1={gaugeMinLine.ptOne.y}
        x2={gaugeMinLine.ptTwo.x}
        y2={gaugeMinLine.ptTwo.y}
        // stroke="url(#Gauge__minGradient)"
        stroke="#00AC47"
        strokeWidth=".11"
      />

      <line
        x1={gaugeMaxLine.ptOne.x}
        y1={gaugeMaxLine.ptOne.y}
        x2={gaugeMaxLine.ptTwo.x}
        y2={gaugeMaxLine.ptTwo.y}
        // stroke="url(#Gauge__maxGradient)"
        stroke="#E21B17"
        strokeWidth=".11"
      />
    </svg>
  );

  return (
    <div className="relative flex justify-center h-full -mt-[35px] items-center">
      {gaugeImage}
      <div className="absolute z-0 flex w-max flex-col left-[50%] top-[80%] translate-x-[-50%] translate-y-[-50%]">
        <div className=" relative flex flex-row justify-center items-baseline">
          <div className="text-4xl font-normal text-[#00AC47] mr-1">
            {formatNumber(minValue)}
          </div>
          <span className="text-lg font-medium text-[#00AC47]">{units}</span>
        </div>
        {/* <div className=" relative flex flex-row justify-center items-baseline">
          <div className="text-xl font-normal text-[#E21B17] mr-1">
            Max {formatNumber(maxValue)}
          </div>
          <span className="text-lg font-medium text-[#E21B17]">{units}</span>
        </div> */}
      </div>
    </div>
  );
};

export default Gauge;
