const LTEIcon = ({ width = "18px", className = "", ...props }) => {
  return (
    <svg
      className={`stroke-contentColor ${className}`}
      width={width}
      height={width}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <path
          d="M10.57,30l.9333-2h8.9928l.9333,2h2.2072L17,15.7778V11H15v4.7778L8.3631,30ZM16,18.3647,17.6965,22h-3.393ZM13.37,24h5.26l.9333,2H12.4369Z"
          transform="translate(0 0)"
        />
        <path
          d="M10.7832,9.3325a7.0007,7.0007,0,0,1,10.4341,0l-1.49,1.334a5,5,0,0,0-7.4537,0Z"
          transform="translate(0 0)"
        />
        <path
          d="M7.1992,6.3994a11.0019,11.0019,0,0,1,17.6006,0L23.2,7.6a9.0009,9.0009,0,0,0-14.4014,0Z"
          transform="translate(0 0)"
        />
        {/* <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          className="fill-none"
          width="32"
          height="32"
        /> */}
      </g>
    </svg>
  );
};

export default LTEIcon;
