import produce from "immer";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { IDevice, ILocationDevice } from "../../interfaces";

interface IGeoMapState {
  geoMapDevices: IDevice[];
  setGeoMapDevices: (x: IDevice[]) => void;

  geoMapSelectedDeviceId: string | null;
  setGeoMapSelectedDeviceId: (x: string) => void;

  allGeoMapDevices: ILocationDevice[];
  setAllGeoMapDevices: (x: ILocationDevice[]) => void;
}

const useGeoMapStore = create<IGeoMapState>()(
  devtools(
    (set) => ({
      geoMapDevices: [],
      setGeoMapDevices: (devices) =>
        set(
          produce((state) => {
            state.geoMapDevices = devices;
          }),
          false,
          "dashboard/setGeoMapDevices"
        ),
      geoMapSelectedDeviceId: null,
      setGeoMapSelectedDeviceId: (id: string) =>
        set(
          produce((state) => {
            state.geoMapSelectedDeviceId = id;
          }),
          false,
          "dashboard/setGeoMapSelectedDeviceId"
        ),
      allGeoMapDevices: [],
      setAllGeoMapDevices: (devices) =>
        set(
          produce((state) => {
            state.allGeoMapDevices = devices;
          }),
          false,
          "dashboard/setAllGeoMapDevices"
        )
    }),

    { name: "geo-map", serialize: { options: true } }
  )
);

export default useGeoMapStore;
