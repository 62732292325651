import { useGetShadowRealtime } from "@/app/shared/hooks/get/shadow-realtime";
import { useUpdateDeviceShadow } from "@/app/shared/hooks/post/update-device-shadow";
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  GlobeAltIcon
} from "@heroicons/react/24/outline";
import { Badge } from "@tremor/react";
import FullGaugeAnimated from "../gauge/full-guage-animated";
import "./speed-test.styles.css";
import { useFleetAndDevicesStore } from "@/store";
import dateService from "@/services/date.service";
import IPIcon from "@/app/shared/icons/ip.icon";

interface ISpeedTestProps {
  data: {
    download: number;
    hostname: string;
    jitter: number;
    latency: number;
    quality: string;
    status: "FINISHED" | "FAILED";
    timestamp: string;
    upload: number;
    ip: string;
  };
}

type TSpeedTestStatus =
  | "UNKNOWN"
  | "REQUESTED"
  | "STARTED"
  | "FINISHED"
  | "FAILED";

const networkConditionColors = {
  UNKNOWN: "slate",
  EXCELLENT: "green",
  GOOD: "amber",
  MODERATE: "cyan",
  POOR: "orange",
  BAD: "red"
};

const SpeedTest: React.FC<ISpeedTestProps> = ({ data }) => {
  const device = useFleetAndDevicesStore((state) => state.selectedDevice);

  const { data: shadow } = useGetShadowRealtime(device?.fleet_id, device?.id);

  const updateShadowMutation = useUpdateDeviceShadow(
    device?.fleet_id,
    device?.id
  );

  const onRunSpeedTestClick = () => {
    updateShadowMutation.mutate(
      {
        shadow: { ...shadow, speedTestStatus: "REQUESTED" }
      },
      {}
    );
  };

  const curStatus: TSpeedTestStatus = shadow?.speedTestStatus ?? "UNKNOWN";

  return (
    <>
      <div className={`h-[90%] relative flex justify-center items-center"`}>
        <div className="absolute top-2 right-6 text-sm">
          {curStatus === "UNKNOWN" || curStatus === "FINISHED" ? (
            <span className="inline-block mr-1 text-sm text-contentColorLight">
              Last Run
            </span>
          ) : null}
          <Badge
            size={"sm"}
            color={curStatus === "FAILED" ? "red" : undefined}
            tooltip={
              curStatus === "FAILED" ? "Last Speed Test Failed" : undefined
            }
            className="inline-block"
          >
            {curStatus === "UNKNOWN"
              ? "Unavailable"
              : curStatus === "STARTED"
              ? "Running"
              : curStatus === "REQUESTED"
              ? "Requested"
              : dateService.convertUTCToLocalDate(data?.timestamp)}
          </Badge>

          {data?.status === "FAILED" &&
          (curStatus === "FINISHED" || curStatus === "FAILED") ? (
            <Badge
              size={"sm"}
              color={"red"}
              tooltip={"Could not run the speed test on this interface"}
              className="block ml-auto mt-2"
            >
              Failed
            </Badge>
          ) : null}
        </div>

        <div
          className={` transition-transform duration-300 transform gap-4 absolute top-2 left-6 text-sm`}
        >
          <div
            className={`flex ${
              curStatus === "FINISHED" ? "flex-row gap-6" : "flex-col"
            }`}
          >
            <div className="flex gap-2 items-start">
              <ArrowDownCircleIcon width={32} color="#00AC47" />
              <div className="flex flex-col gap-0">
                <span className="text-sm">
                  {" "}
                  Download{" "}
                  <span className="text-contentColorLight ml-1">Mbps</span>
                </span>
                <span
                  className={`${
                    curStatus === "FINISHED" ? "text-2xl" : "text-xl"
                  }`}
                >
                  {" "}
                  {curStatus === "UNKNOWN" ||
                  curStatus === "REQUESTED" ||
                  curStatus === "STARTED"
                    ? "--"
                    : data?.download
                    ? (data?.download).toFixed(2)
                    : "--"}
                </span>
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <ArrowUpCircleIcon width={32} color="#1B65D4" />
              <div className="flex  flex-col gap-0">
                <span className="text-sm">
                  {" "}
                  Upload{" "}
                  <span className="text-contentColorLight ml-1">Mbps</span>
                </span>
                <span
                  className={`${
                    curStatus === "FINISHED" ? "text-2xl" : "text-xl"
                  }`}
                >
                  {" "}
                  {curStatus === "UNKNOWN" ||
                  curStatus === "REQUESTED" ||
                  curStatus === "STARTED"
                    ? "--"
                    : data?.upload
                    ? (data?.upload).toFixed(2)
                    : "--"}
                </span>
              </div>
            </div>
          </div>
          {curStatus === "FINISHED" ? (
            <div className="flex gap-1 mt-10">
              <span className="text-contentColorLight">Latency</span>
              <span className={`text-sm mr-6`}>
                {(data?.latency / 1000).toFixed(2) ?? "-"} ms
              </span>
              <span className="text-contentColorLight">Jitter</span>
              <span className={`text-sm`}>
                {(data?.jitter / 1000).toFixed(2) ?? "-"} ms
              </span>
            </div>
          ) : null}
        </div>

        {curStatus === "UNKNOWN" ||
        curStatus === "REQUESTED" ||
        curStatus === "STARTED" ? null : (
          <div
            data-tooltip-id="panel-content-info"
            data-tooltip-content="Public IP"
            className="flex gap-1 absolute bottom-12 left-6 items-center text-sm"
          >
            <IPIcon
              width={"20px"}
              className="text-contentColorLight stroke-contentColorLight fill-contentColorLight"
            />
            <span className="text-base">
              {!data?.ip ? "IP Unavailable" : data?.ip}
            </span>
          </div>
        )}
        <div className="flex gap-1 absolute bottom-6 left-6 items-center text-sm">
          <GlobeAltIcon width={20} className="text-contentColorLight" />
          <span className="text-base">
            {" "}
            {curStatus === "UNKNOWN" ||
            curStatus === "REQUESTED" ||
            curStatus === "STARTED"
              ? "Backhaul Unavailable"
              : data?.hostname === "Unknown" || !data?.hostname
              ? "Backhaul Unavailable"
              : data?.hostname}
          </span>
        </div>
        <div className="flex flex-col gap-1 absolute bottom-6 right-6 text-sm">
          <span className="text-sm text-contentColorLight">
            Network Condition
          </span>
          <Badge
            color={networkConditionColors[data?.quality ?? "UNKNOWN"]}
            size={"lg"}
          >
            {data?.quality ?? "UNKNOWN"}
          </Badge>
        </div>

        {curStatus === "UNKNOWN" ||
        curStatus === "FINISHED" ||
        curStatus === "FAILED" ? (
          <div
            className={`relative flex justify-center items-center transition-transform duration-300 transform ${
              curStatus === "FINISHED"
                ? "translate-x-24 translate-y-8"
                : "translate-x-0 translate-y-0"
            }`}
          >
            <button
              onClick={onRunSpeedTestClick}
              className={`circular-button cursor-pointer text-contentColor transition-transform duration-300 transform ${
                curStatus === "FINISHED" ? "w-24 h-24" : "w-44 h-44"
              } rounded-full relative`}
            ></button>
            <span
              onClick={onRunSpeedTestClick}
              className={`absolute z-10 cursor-pointer text-contentColor font-medium ${
                curStatus === "FINISHED" ? "text-base" : "text-2xl"
              }`}
            >
              Run Test
            </span>
          </div>
        ) : curStatus === "REQUESTED" || curStatus === "STARTED" ? (
          <>
            <div className="mt-12">
              <FullGaugeAnimated
                max={100}
                min={0}
                animate={true}
                maxValue={90}
                minValue={10}
                title="Test"
                units="Ms"
              />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default SpeedTest;
