import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RoutePermission } from "../models";
import useLangStore from "../store/lang.store";
import { AuthCallback } from "./auth/auth-callback.component";
import Logout from "./auth/logout.component";
import Layout from "./layout";
import Invite from "./auth/invite.component";
import { IntlProvider } from "react-intl";
import { DEFAULT_LOCALE, messages } from "../i18n";
import { NotFound, PrivateRoute, Unauthorized } from "./shared/components";
import { RoutePermissionType } from "./shared/config";

import { DashboardHome } from "./dashboard";
import Jump from "./auth/jump.component";
import useThemeStore from "@store/theme.store";

function AppRouter() {
  const loginPermission = React.useMemo(
    () => RoutePermission.factory([], RoutePermissionType.OnlyLoginRequired),
    []
  );
  const lang = useLangStore((state) => state.value);
  const locale = lang;

  const [theme, setTheme] = useThemeStore((state) => [
    state.theme,
    state.setTheme
  ]);

  useEffect(() => {
    const documentElement = document.documentElement;

    if (theme === "none") {
      // FIXME: Enable when there's full dark theme support
      // if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      //   documentElement.classList.add("dark-default", "dark");
      //   documentElement.classList.remove("golain");
      //   setTheme("dark-default");
      // } else {
      documentElement.classList.add("golain");
      documentElement.classList.remove("dark-default", "dark");
      setTheme("golain");
      // }
    } else if (theme === "dark-default") {
      documentElement.classList.add("dark-default", "dark");
      documentElement.classList.remove("golain");
    } else if (theme === "golain") {
      documentElement.classList.remove("dark-default", "dark");
      documentElement.classList.add("golain");
    }
  }, [setTheme, theme]);

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={DEFAULT_LOCALE}
      messages={messages[locale]}
    >
      <Routes>
        <Route path="/callback" element={<AuthCallback />} />
        <Route element={<Jump />}>
          {/* A secure/private route is set like this*/}
          <Route element={<PrivateRoute routePermission={loginPermission} />}>
            <Route path="/invite" element={<Invite />} />
            <Route index element={<Navigate to="/home" />} />
            <Route path="/logout" element={<Logout />} />

            <Route path="/:org_id/:proj_id/" element={<Layout />}>
              <Route
                path="dashboard"
                element={<DashboardHome renderAs="PROJECT_DASHBOARD" />}
              />
              <Route
                path="device/:device_id"
                element={<DashboardHome renderAs="DEVICE_DASHBOARD" />}
              />
              <Route
                path="fleet/:fleet_id"
                element={<DashboardHome renderAs="FLEET_DASHBOARD" />}
              />
            </Route>
          </Route>
        </Route>

        {/* generic routes */}
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="error" element={<NotFound />} />
        <Route path="not-found" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </IntlProvider>
  );
}

export default AppRouter;
