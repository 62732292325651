import IPIcon from "@/app/shared/icons/ip.icon";
import LTEIcon from "@/app/shared/icons/lte.icon";
import RJ45Icon from "@/app/shared/icons/rj45.icon";
import { IDevice } from "@/interfaces";
import {
  GlobeAltIcon,
  WrenchScrewdriverIcon
} from "@heroicons/react/24/outline";
import { Badge } from "@tremor/react";
import { useMemo } from "react";
import { Tooltip } from "react-tooltip";

export interface IPortInfo {
  enabled: boolean;
  physicalPort: number;
  configuredAs: string;
  portStatus: "CONNECTED" | "DISCONNECTED";
  internetStatus: "UP" | "DOWN" | "NC"; // NC = Not Connected
  ISP?: string;
  privateIPv4: string;
  privateIPv6: string;
  publicIP: string;
  name: string;
  isLanPort: boolean;
}

interface IPortsInfoProps {
  portInfos: IPortInfo[];
}

const PortsInfo: React.FC<IPortsInfoProps> = ({ portInfos }) => {
  return (
    <>
      <div className="grid grid-cols-2 grid-rows-2 h-[90%]">
        {portInfos.map((portInfo, ind) => (
          <div
            key={portInfo.name + ind}
            className="flex flex-col items-center cursor-pointer"
          >
            {ind < 2 ? portInfo.name : null}
            <RJ45Icon
              data-tooltip-id={`ports-info-tooltip`}
              data-tooltip-portInfo={JSON.stringify(portInfo)}
              width={"50%"}
              className={`h-[60%] ${
                portInfo.enabled
                  ? portInfo.portStatus === "CONNECTED"
                    ? "fill-green-500"
                    : portInfo.portStatus === "DISCONNECTED"
                    ? "fill-red-500"
                    : ""
                  : "fill-gray-500"
              }`}
            />
            {ind > 1 ? portInfo.name : null}
          </div>
        ))}
      </div>
    </>
  );
};

export const PortInfoTooltip = ({ device }: { device: IDevice }) => {
  const deviceConnected = useMemo(
    () => !!device?.connected,
    [device?.connected]
  );

  return (
    <Tooltip
      id={`ports-info-tooltip`}
      clickable
      variant="light"
      positionStrategy="fixed"
      place="right"
      opacity={1}
      className="z-[100] shadow-lg "
      render={({ activeAnchor }) => {
        const portInfoJSON = activeAnchor?.getAttribute(
          "data-tooltip-portInfo"
        );
        const portInfo: IPortsInfoProps["portInfos"][0] = JSON.parse(
          portInfoJSON ?? "{}"
        );

        if (!portInfo) return null;

        return (
          <div className="text-base">
            <h2 className="font-bold text-xl mb-2">{portInfo.name}</h2>

            <span className="flex gap-3 mb-1">
              <label className="text-contentColorLight flex gap-2">
                <RJ45Icon width="18px" className=" mt-0.5" />
                Physical Port:
              </label>
              <span className="font-bold ">
                {portInfo.physicalPort ?? "Not Available"}
              </span>
            </span>

            <span className="flex gap-3 mb-1">
              <label className="text-contentColorLight flex gap-2">
                <RJ45Icon width="18px" className=" mt-0.5" />
                Port Status:
              </label>
              <span className="font-bold">
                <Badge
                  color={
                    portInfo.enabled
                      ? portInfo.portStatus === "CONNECTED"
                        ? "green"
                        : portInfo.portStatus === "DISCONNECTED"
                        ? "red"
                        : "slate"
                      : "slate"
                  }
                >
                  {portInfo.enabled
                    ? portInfo.portStatus === "CONNECTED"
                      ? "CONNECTED"
                      : portInfo.portStatus === "DISCONNECTED"
                      ? "DISCONNECTED"
                      : ""
                    : "Not Enabled"}
                </Badge>
              </span>
            </span>

            {portInfo.enabled ? (
              <>
                <span className="flex gap-3 mb-1">
                  <label className="text-contentColorLight flex gap-2">
                    <WrenchScrewdriverIcon width={18} />
                    Configured As:
                  </label>
                  <span className="font-bold">
                    {portInfo.configuredAs ?? "Not Available"}
                  </span>
                </span>

                {!portInfo.isLanPort ? (
                  <span className="flex gap-3 mb-1">
                    <label className="text-contentColorLight flex gap-2">
                      <LTEIcon
                        width="18px"
                        className="!stroke-contentColorLight !fill-contentColorLight mt-0.5"
                      />
                      Internet Connectivity Status:
                    </label>
                    <span className="font-bold">
                      <Badge
                        color={
                          portInfo.internetStatus === "UP"
                            ? "green"
                            : portInfo.internetStatus === "DOWN"
                            ? "red"
                            : "slate"
                        }
                      >
                        {portInfo.internetStatus === "UP"
                          ? "UP"
                          : portInfo.internetStatus === "DOWN"
                          ? "Down"
                          : ""}
                      </Badge>
                    </span>
                  </span>
                ) : null}

                {!portInfo.isLanPort ? (
                  <span className="flex gap-3 mb-1">
                    <label className="text-contentColorLight flex gap-2">
                      <GlobeAltIcon width={18} />
                      ISP:
                    </label>
                    <span className="font-bold">
                      {portInfo.ISP ?? "Not Available"}
                    </span>
                  </span>
                ) : null}

                {portInfo.privateIPv4 ? (
                  <span className="flex gap-3 mb-1">
                    <label className="text-contentColorLight flex gap-2">
                      <IPIcon
                        width={"18px"}
                        className="!fill-contentColorLight !stroke-contentColorLight"
                      />
                      Local (IPv4):
                    </label>
                    <span className="font-bold">
                      <Badge
                        color={
                          deviceConnected &&
                          portInfo.portStatus === "CONNECTED"
                            ? "green"
                            : "red"
                        }
                        className="font-mono font-normal"
                      >
                        {portInfo.privateIPv4 ?? "Not Available"}
                      </Badge>
                    </span>
                  </span>
                ) : null}

                {portInfo.privateIPv6 ? (
                  <span className="flex gap-3 mb-1">
                    <label className="text-contentColorLight flex gap-2">
                      <IPIcon
                        width={"18px"}
                        className="!fill-contentColorLight !stroke-contentColorLight"
                      />
                      Local (IPv6):
                    </label>
                    <span className="font-bold">
                      <Badge
                        color={
                          deviceConnected &&
                          portInfo.portStatus === "CONNECTED"
                            ? "green"
                            : "red"
                        }
                        className="font-mono font-normal"
                      >
                        {portInfo.privateIPv6 ?? "Not Available"}
                      </Badge>
                    </span>
                  </span>
                ) : null}

                {portInfo.publicIP ? (
                  <span className="flex gap-3 mb-1">
                    <label className="text-contentColorLight flex gap-2">
                      <IPIcon
                        width={"18px"}
                        className="!fill-contentColorLight !stroke-contentColorLight"
                      />
                      Public IP:
                    </label>
                    <span className="font-bold">
                      <Badge
                        color={
                          deviceConnected &&
                          portInfo.portStatus === "CONNECTED"
                            ? "green"
                            : "red"
                        }
                        className="font-mono font-normal"
                      >
                        {portInfo.publicIP ?? "Not Available"}
                      </Badge>
                    </span>
                  </span>
                ) : null}
              </>
            ) : null}
          </div>
        );
      }}
    ></Tooltip>
  );
};

export default PortsInfo;
