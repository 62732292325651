import React, { useEffect, useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";

const FullGaugeAnimated: React.FC<any> = ({ animate = false }) => {
  const [curVal, setCurVal] = useState(50);

  useEffect(() => {
    let intervalId;
    if (animate) {
      intervalId = setInterval(() => {
        let delta = Math.random() * 20 - 10;
        let newVal = curVal + delta;

        if (newVal > 100) newVal = 100;
        if (newVal < 0) newVal = 0;

        setCurVal(newVal);
      }, 500);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [animate, curVal]);

  return (
    <div className="relative flex justify-center h-full -mb-[20px] items-center pt-12">
      <ReactSpeedometer
        paddingVertical={18}
        maxSegmentLabels={0}
        value={curVal}
        minValue={0}
        maxValue={100}
        startColor={"#1B65D4"}
        endColor={"#43D981"}
        currentValueText=" "
      />
    </div>
  );
};

export default FullGaugeAnimated;
