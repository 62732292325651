interface WiFiInfoProps {
  lines: Array<{
    text: string;
    className: string[];
  }>;
}

const TextPanel: React.FC<WiFiInfoProps> = ({ lines }) => {
  return (
    <>
      <div
        className={`grid grid-cols-1 grid-rows-${lines.length} h-[90%] place-items-center`}
      >
        {lines.map((line, ind) => (
          <div key={line.text + ind} className={`h-full`}>
            {line.text.split("$$").map((linePart, linePartInd) => (
              <span className={line.className[linePartInd]}>{linePart}</span>
            ))}
          </div>
        ))}
      </div>
      {/* use all the styles so that tailwind includes them in the build */}
      <span className="hidden text-sm text-xs text-lg text-xl text-2xl text-3xl text-4xl text-5xl text-6xl text-7xl text-8xl text-9xl text-[3rem]"></span>
    </>
  );
};

export default TextPanel;
