import { useParams } from "react-router-dom";
import AllDashboards from "./dashboard-all-dashbaords.component";
import { useGetDevices } from "../shared/hooks/get/devices";
import ShowLoading from "../shared/components/loading.component";
import { useGetDeviceBlueprints } from "../shared/hooks/get/device-panels";
import { useFleetAndDevicesStore } from "@/store";
import { useEffect } from "react";
import { useGetFleetBlueprints } from "../shared/hooks/get/fleet-blueprints";
import { useGetFleets } from "../shared/hooks/get/fleets";

interface ILandingProps {
  renderAs: "PROJECT_DASHBOARD" | "DEVICE_DASHBOARD" | "FLEET_DASHBOARD";
}

const Landing: React.FC<ILandingProps> = ({ renderAs }) => {
  const params = useParams();

  const deviceId = params["device_id"];
  const fleetId = params["fleet_id"];

  const { data: fleets, isLoading: isFleetsLoading } = useGetFleets({
    fleet_id: fleetId
  });

  const { data: devicesResponse, isLoading: isDevicesLoading } = useGetDevices(
    {
      device_id: deviceId
    }
  );

  const setSelectedDevice = useFleetAndDevicesStore(
    (state) => state.setSelectedDevice
  );

  useEffect(() => {
    setSelectedDevice(devicesResponse?.devices?.[0]);
  }, [devicesResponse?.devices?.[0]]);

  const { data: dashboardBlueprints, isLoading: isBlueprintsLoading } =
    useGetDeviceBlueprints(devicesResponse?.devices?.[0]?.fleet_id, deviceId, {
      kind: "DASHBOARD"
    });

  const {
    data: fleetDashboardBlueprints,
    isLoading: isFleetBlueprintsLoading
  } = useGetFleetBlueprints(fleetId, {
    kind: "DASHBOARD"
  });

  return isDevicesLoading ||
    isBlueprintsLoading ||
    isFleetBlueprintsLoading ||
    isFleetsLoading ? (
    <ShowLoading />
  ) : (
    <AllDashboards
      renderAs={renderAs}
      device={devicesResponse?.devices?.[0]}
      fleet={fleets?.[0]}
      dashboardBlueprints={
        renderAs === "DEVICE_DASHBOARD"
          ? dashboardBlueprints
          : fleetDashboardBlueprints
      }
    />
  );
};

export default Landing;
