import { MapPinIcon } from "@heroicons/react/24/outline";
import { Icon, LatLngExpression } from "leaflet";
import { Marker, Popup } from "react-leaflet";

interface IDeviceLocationMarker {
  deviceInfo: any;
  position: LatLngExpression;
  address: string;
}

const DeviceLocationMarker: React.FC<IDeviceLocationMarker> = ({
  deviceInfo,
  position,
  address
}) => {
  return !position ? null : (
    <Marker
      icon={
        new Icon({
          iconUrl:
            "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
          shadowUrl:
            "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41]
        })
      }
      position={position}
    >
      <Popup>
        <div className="flex flex-col justify-center items-center">
          <h2 className="text-lg text-center font-bold mb-2">
            {deviceInfo.serial_number}
          </h2>
          <div className="flex gap-1">
            <MapPinIcon width={20} className="min-w-[20px]" />
            <address className="text-xs">{address}</address>
          </div>
          {position[0]},{position[1]}
          <a
            href={`http://maps.google.com/maps?z=14&t=m&q=loc:${position[0]}+${position[1]}`}
            target="_blank"
            rel="noreferrer noopener"
            className="block !text-white bg-primary rounded-md px-3 py-2 mx-auto w-min whitespace-nowrap mt-2"
          >
            View on Google Maps
          </a>
        </div>
      </Popup>
    </Marker>
  );
};

export default DeviceLocationMarker;
