import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  ArrowsUpDownIcon
} from "@heroicons/react/24/outline";

interface IPacketLossGraphProps {
  data: {
    txDropped: number;
    rxDropped: number;
    txPackets: number;
    rxPackets: number;
  };
}

const PacketLossGraph: React.FC<IPacketLossGraphProps> = ({ data }) => {
  return (
    <>
      <div className="p-4 rounded-lg flex justify-around items-center h-full -mt-[34px]">
        <div className="flex items-center flex-col mt-20">
          <div className="flex items-center gap-2">
            <ArrowDownCircleIcon width={22} color="#f97316" />
            <div className="text-base font-medium">
              Download Loss{" "}
              <span className="text-contentColorLight font-normal text-sm">
                {" "}
                %
              </span>
            </div>
          </div>
          <div className="text-3xl font-normal">
            {data.rxPackets === 0
              ? 0.0
              : ((data.rxDropped / data.rxPackets) * 100).toFixed(2)}
          </div>
        </div>
        <div className="flex items-center flex-col">
          <div className="flex items-center gap-2">
            <ArrowsUpDownIcon width={28} color="red" />
            <div className="text-xl font-medium">
              Total Loss{" "}
              <span className="text-contentColorLight font-normal text-sm">
                {" "}
                %
              </span>
            </div>
          </div>
          <div className="text-6xl font-semibold">
            {data.txPackets + data.rxPackets === 0
              ? 0.0
              : (
                  ((data.txDropped + data.rxDropped) /
                    (data.txPackets + data.rxPackets)) *
                  100
                ).toFixed(2)}
          </div>
        </div>
        <div className="flex items-center flex-col mt-20">
          <div className="flex items-center gap-2">
            <ArrowUpCircleIcon width={22} color="#eab308" />
            <div className="text-base font-medium">
              Upload Loss{" "}
              <span className="text-contentColorLight font-normal text-sm">
                {" "}
                %
              </span>
            </div>
          </div>
          <div className="text-3xl font-normal">
            {data.txPackets === 0
              ? 0.0
              : ((data.txDropped / data.txPackets) * 100).toFixed(2)}
          </div>
        </div>
      </div>
    </>
  );
};

export default PacketLossGraph;
