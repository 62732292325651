const RJ45Icon = ({
  width = "18px",
  height = undefined,
  className = "",
  ...props
}) => {
  return (
    <svg
      className={`stroke-contentColor ${className}`}
      width={width}
      height={height ?? width}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <g>
          <g>
            <path
              d="M486.4,68.267H25.6c-14.134,0-25.6,11.466-25.6,25.6v324.267c0,14.134,11.466,25.6,25.6,25.6h460.8
				c14.134,0,25.6-11.466,25.6-25.6V93.867C512,79.733,500.534,68.267,486.4,68.267z M494.933,418.133
				c0,4.708-3.825,8.533-8.533,8.533H25.6c-4.708,0-8.533-3.825-8.533-8.533V93.867c0-4.708,3.825-8.533,8.533-8.533h460.8
				c4.708,0,8.533,3.825,8.533,8.533V418.133z"
            />
            <path
              d="M426.667,128H85.333c-4.713,0-8.533,3.82-8.533,8.533v162.133c0,4.713,3.82,8.533,8.533,8.533H128v34.133
				c0,4.713,3.82,8.533,8.533,8.533h51.2v25.6c0,4.713,3.82,8.533,8.533,8.533h119.467c4.713,0,8.533-3.82,8.533-8.533v-25.6h51.2
				c4.713,0,8.533-3.821,8.533-8.533V307.2h42.667c4.713,0,8.533-3.82,8.533-8.533V136.533C435.2,131.821,431.38,128,426.667,128z
				 M418.133,290.133h-42.667c-4.713,0-8.533,3.82-8.533,8.533V332.8h-51.2c-4.713,0-8.533,3.821-8.533,8.533v25.6H204.8v-25.6
				c0-4.713-3.82-8.533-8.533-8.533h-51.2v-34.133c0-4.713-3.82-8.533-8.533-8.533H93.867V145.067h17.067v17.067
				c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533v-17.067h17.067v17.067c0,4.713,3.821,8.533,8.533,8.533
				c4.713,0,8.533-3.82,8.533-8.533v-17.067H179.2v17.067c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533v-17.067h17.067
				v17.067c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533v-17.067h17.067v17.067c0,4.713,3.82,8.533,8.533,8.533
				s8.533-3.82,8.533-8.533v-17.067H281.6v17.067c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533v-17.067h17.067v17.067
				c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533v-17.067h17.067v17.067c0,4.713,3.82,8.533,8.533,8.533
				s8.533-3.82,8.533-8.533v-17.067H384v17.067c0,4.713,3.821,8.533,8.533,8.533c4.713,0,8.533-3.82,8.533-8.533v-17.067h17.067
				V290.133z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RJ45Icon;
