import { LineChart } from "@tremor/react";
import { IGraph } from "@interfaces/index";
import { Color } from "@tremor/react";
import { useEffect, useRef, useState } from "react";
import { formatNumber } from "@/app/shared/utils/dashboard.utils";

/* 
    Component: Line Graph
    Props:  {
        chartdata : data distribution [Array of objects]
        title : Title of graph [ panel name ]
        categories : items, keys from data to chart bars for [ Array of string ]
        colors : [ Array of string ] should be same in length of categories
        datakey :  name of key from data that represent value representation over all objects
        yMax: maximun vaues on y axis 
        yMin: minimum vaues on y axis
    }
    Author: Aaditya Kashid
*/

const COLORS: { [x: string]: Color } = {
  slate: "slate",
  gray: "gray",
  zinc: "zinc",
  neutral: "neutral",
  stone: "stone",
  red: "red",
  orange: "orange",
  amber: "amber",
  yellow: "yellow",
  lime: "lime",
  green: "green",
  emerald: "emerald",
  teal: "teal",
  cyan: "cyan",
  sky: "sky",
  blue: "blue",
  indigo: "indigo",
  violet: "violet",
  purple: "purple",
  fuchsia: "fuchsia",
  pink: "pink",
  rose: "rose"
};

const LineGraph: React.FC<IGraph> = ({
  chartdata,
  categories,
  colors,
  index,
  valueFormatter,
  // yMin,
  // yMax,
  showExample = false
}) => {
  const _valueFormatter = (val) => formatNumber(val).toString();
  const containerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(200);
  const [settingHeight, setSettingHeight] = useState(false);
  const heightTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (showExample) {
      return;
    }
    const timeout = heightTimeout.current;
    const resizeObserver = new ResizeObserver((parentDiv) => {
      const { height } = parentDiv[0].contentRect;
      setSettingHeight(true);
      setHeight(height);

      if (timeout) {
        clearTimeout(timeout);
      }
      heightTimeout.current = setTimeout(() => {
        setSettingHeight(false);
      }, 1000);
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      clearTimeout(timeout);
    };
  }, [showExample]);

  return (
    <div className="flex flex-col flex-grow h-full">
      <div
        ref={containerRef}
        onMouseDown={(e) => {
          // to prevent react-grid-layout from dragging
          e.preventDefault();
          e.stopPropagation();
        }}
        className="px-4 pb-2 flex-grow h-full overflow-auto"
      >
        {!settingHeight ? (
          <LineChart
            data={chartdata}
            index={index}
            curveType="monotone"
            style={{
              height: showExample ? "300px" : `${height - 16}px`
            }}
            categories={categories}
            colors={colors.flat().map((c) => COLORS[c])}
            valueFormatter={valueFormatter ?? _valueFormatter}
            // yAxisWidth={50}
          />
        ) : null}
      </div>
    </div>
  );
};

export default LineGraph;
