import {
  ArrowTrendingDownIcon,
  UserIcon,
  WifiIcon,
  WrenchScrewdriverIcon
} from "@heroicons/react/24/outline";
import { Badge } from "@tremor/react";
import { Tooltip } from "react-tooltip";

interface WiFiInfoProps {
  wifiInfos: Array<{
    name: string;
    configured: boolean;
    ssid: string;
    connectedDevices: number;
    signalStrength: string;
    signalQuality: string;
    noiseLevel: string;
    packetLoss: number;
  }>;
}

const WiFiInfo: React.FC<WiFiInfoProps> = ({ wifiInfos }) => {
  return (
    <>
      <div
        className={`grid grid-cols-1 grid-rows-${wifiInfos.length} h-[90%] place-items-center`}
      >
        {wifiInfos.map((wifiInfo, ind) => (
          <div
            key={wifiInfo.name + ind}
            className="flex flex-col items-center font-bold cursor-pointer h-full"
          >
            {ind < 2 ? wifiInfo.name : null}
            <WifiIcon
              data-tooltip-id={`wifi-info-tooltip`}
              data-tooltip-wifiInfo={JSON.stringify(wifiInfo)}
              className={`h-[60%] ${
                wifiInfo.configured ? "stroke-green-500" : "stroke-gray-500"
              }`}
            />
            {ind > 1 ? wifiInfo.name : null}
          </div>
        ))}
      </div>
    </>
  );
};

export const WifiInfoTooltip = () => {
  return (
    <Tooltip
      id={`wifi-info-tooltip`}
      variant="light"
      opacity={1}
      className="z-[100] shadow-lg "
      place="right"
      render={({ activeAnchor }) => {
        const portInfoJSON = activeAnchor?.getAttribute(
          "data-tooltip-wifiInfo"
        );
        const wifiInfo: WiFiInfoProps["wifiInfos"][0] = JSON.parse(
          portInfoJSON ?? "{}"
        );

        if (!wifiInfo) return null;

        return (
          <div>
            <h2 className="font-bold text-xl mb-2">{wifiInfo.name}</h2>

            <span className="flex gap-3 mb-1">
              <label className="text-contentColorLight flex gap-2">
                <WrenchScrewdriverIcon width={18} />
                Status:
              </label>
              <Badge color={wifiInfo.configured ? "blue" : "slate"}>
                {wifiInfo.configured ? "Configured" : "Not Configured"}
              </Badge>
            </span>

            {wifiInfo.configured ? (
              <>
                <span className="flex gap-3 mb-1">
                  <label className="text-contentColorLight flex gap-2">
                    <UserIcon width={18} />
                    WiFi SSID:
                  </label>
                  <span className="">
                    <Badge>{wifiInfo.ssid}</Badge>
                  </span>
                </span>

                {/* <span className="flex gap-3 mb-1">
              <label className="text-contentColorLight flex gap-2">
                <WifiIcon width={18} />
                Connected Devices:
              </label>
              <span className="font-bold ">{wifiInfo.connectedDevices}</span>
            </span>

            <span className="flex gap-3 mb-1">
              <label className="text-contentColorLight flex gap-2">
                <SignalIcon width={18} />
                Signal Strength:
              </label>
              <span className="font-bold ">{wifiInfo.signalStrength}</span>
            </span>

            <span className="flex gap-3 mb-1">
              <label className="text-contentColorLight flex gap-2">
                <StarIcon width={18} />
                Signal Quality:
              </label>
              <span className="font-bold ">{wifiInfo.signalQuality}</span>
            </span>

            <span className="flex gap-3 mb-1">
              <label className="text-contentColorLight flex gap-2">
                <SignalSlashIcon width={18} />
                Noise Level:
              </label>
              <span className="font-bold ">{wifiInfo.noiseLevel}</span>
            </span> */}

                <span className="flex gap-3 mb-1">
                  <label className="text-contentColorLight flex gap-2">
                    <ArrowTrendingDownIcon width={18} />
                    Packet Loss:
                  </label>
                  <span className="font-bold ">
                    <Badge color={"red"}>{wifiInfo.packetLoss}</Badge>
                  </span>
                </span>
              </>
            ) : null}
          </div>
        );
      }}
    ></Tooltip>
  );
};

export default WiFiInfo;
