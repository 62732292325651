import React, { useEffect, useId, useRef } from "react";
import L, { Map } from "leaflet";
import { MapContainer, TileLayer } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import DeviceLocationMarker from "./device-location-marker.component";

const defaultIconConfig = {
  iconRetinaUrl: require("leaflet/dist/images/marker-icon.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
};

L.Icon.Default.mergeOptions(defaultIconConfig);

interface IDeviceLocationMapViewProps {
  device?: {
    lat: number;
    lng: number;
    address: string;
    [key: string]: any;
  };
}

const DeviceLocationMapView: React.FC<IDeviceLocationMapViewProps> = ({
  device
}) => {
  const mapRef = useRef<Map>();
  const resizeObserverRef = React.useRef<ResizeObserver>(null);

  const id = useId();

  useEffect(() => {
    const resizeObserver = resizeObserverRef.current;
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (device?.lat && device?.lng && mapRef.current) {
      mapRef.current.flyTo([device?.lat, device?.lng], 15, { animate: false });
    }
  }, [device?.lat, device?.lng]);

  return (
    <div className="h-full w-full p-2 mb-2 -mt-[15px]">
      <MapContainer
        ref={(ref) => {
          if (!ref) return;
          mapRef.current = ref;

          const container = document.getElementById(id);
          if (!container) return;

          L.DomEvent.disableClickPropagation(
            container
          ).disableScrollPropagation(container);

          resizeObserverRef.current = new ResizeObserver(() => {
            if (!ref) {
              return;
            }
            if (ref.getContainer()) {
              ref?.invalidateSize({ pan: false });
            }
          });
          if (container) {
            resizeObserverRef.current.observe(container);
          }
        }}
        id={id}
        center={[device?.lat ?? 0, device?.lng ?? 0]}
        zoom={14}
        zoomSnap={1}
        style={{
          width: "100%",
          height: "calc(100% - 0px)",
          marginTop: "6px",
          borderRadius: "6px",
          zIndex: 0
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {device?.lat && device?.lng ? (
          <DeviceLocationMarker
            deviceInfo={device}
            address={device.address}
            position={[device.lat, device.lng]}
          />
        ) : null}
      </MapContainer>
    </div>
  );
};

export default React.memo(DeviceLocationMapView);
